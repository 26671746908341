import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import '../../static/CSS/MedicineNotAvalible.css';

function MedicineNotPresent({ token }) {
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);
  const pouchNumber = useSelector((state) => state.user.pouchNumber);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const [removedStockData, setRemovedStockData] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);



  useEffect(() => {
    // Fetch dropdown options from API
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('api/dropdown_options/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const notPresentReasons = response.data.filter(item => item.Dropdown_Name === 'Not_Present_Reason');
        setDropdownOptions(notPresentReasons.map(item => item.Dropdown_Options));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };
    
    fetchDropdownOptions();
  }, [token]);



  const handleSelectMedicine = (medicine) => {
    if (selectedMedicine === medicine) {
      // If the medicine is already selected, deselect it
      setSelectedMedicine(null);
    } else {
      // Otherwise, select the new medicine
      setSelectedMedicine(medicine);
    }
  };
  

  const handleDeselectMedicine = () => {
    setSelectedMedicine(null);
  };


  useEffect(() => {
    const fetchPouchContents = async () => {
      try {
        const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const dataWithTransactionType = response.data.map((item) => ({
          ...item,
          transactionType: '',
          amountToRemove: 0,
          comment: '',
        }));
        
        console.log(response);
        setFilteredTableData(dataWithTransactionType);
  
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };
  
    if (pouchNumber) {
      fetchPouchContents();
    } else {
      setFilteredTableData([]);
    }
  }, [pouchBarcode, token, updateKey]);
  


  async function fetchRemovedStock() {
    const response = await axios.get("/api/RemovedStock/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    setRemovedStockData(response.data);
  }
  
  useEffect(() => {
    fetchRemovedStock();
  }, []);
  
  const handleSubmit = async (item, e) => {
    e.preventDefault();
  
    // Check if the amount to remove is greater than the amount available
    if (item.amountToRemove > item.amount) {
      const confirmContinue = window.confirm(
        'The inputted amount is more than the amount in the pouch. Do you want to continue?'
      );
      if (!confirmContinue) {
        return;
      }
    }
  
    try {
      // Fetch MedicineInPouch data by pouch_barcode and medicine_barcode using the new filter view
      const medicineInPouchResponse = await axios.get(`/api/MedicineInPouch/filter/?pouch_barcode=${pouchBarcode}&medicine_barcode=${item.Drug_Barcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const medicineInPouchData = medicineInPouchResponse.data;
  
      // Check if there are any records found
      if (!medicineInPouchData || medicineInPouchData.length === 0) {
        alert("No medicine found in the pouch with the specified barcode.");
        return;
      }
  
      // Handle the case for each record found
      for (const pouchData of medicineInPouchData) {
        const newAmount = pouchData.amount - parseInt(item.amountToRemove);
  
        // If the new amount is zero, delete the record
        if (newAmount === 0) {
          await axios.delete(`/api/MedicineInPouch/delete/${pouchData.unique_id}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else if (newAmount > 0) {
          // Update the record with the new amount
          await axios.put(`/api/MedicineInPouch/update/${pouchData.unique_id}/`, {
            drug_barcode: item.Drug_Barcode,
            pouch_barcode: pouchBarcode,
            amount: newAmount,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      }
  
      // Update pouch history
      await axios.put(`/api/update_pouch_history/${item.unique_id}/`, {
        user_unpacked: userBarcode,
        amount: item.amountToRemove,
        datetime_unpacked: new Date().toISOString(),
        status: 'Unpacked',
        comments: item.comment,  
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Update stock holding history
      await axios.post('/api/StockHoldingHistory/create/', {
        location: stationBarcode,
        user_barcode: userBarcode,
        drug_barcode: item.Drug_Barcode,
        transaction: item.transactionType,
        amount: item.amountToRemove,
        datetime_transaction: new Date().toISOString(),
        comments: item.comment,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Refresh the table after submitting
      setUpdateKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error('Error removing medicine:', error);
      alert('Error removing medicine');
    }
  };
  
  
  
  const sortTable = (property) => {
    const sortedData = [...filteredTableData].sort((a, b) => {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    });
  
    setFilteredTableData(sortedData);
  };
  



  return (
    <div className="medicine-absent-container">
          <h1 className='Pouch-Number'>Pouch Number: {pouchNumber}</h1> {/* Add this line */}

      {filteredTableData.map((item) => (
        item.amount > 0 && (
          <div key={item.unique_id} className={`medicine-item ${selectedMedicine === item ? 'selected' : ''}`}>
            <div className="medicine-info" onClick={() => handleSelectMedicine(item)}>
              <div className="medicine-title">{item.Medicine_Name}</div>
              <div className="medicine-batch">{item.Batch_Number}</div>
            </div>

              {selectedMedicine && selectedMedicine.unique_id === item.unique_id && (
                <form onSubmit={(e) => handleSubmit(item, e)} className="medicine-form">
                  <div className="medicine-info">
                    <span>Expiry Date: {new Date(item.Expiry_Date).toLocaleDateString()}</span>
                  </div>
                  <div className="medicine-actions">
                  <select
                    value={item.transactionType}
                    onChange={(e) => {
                      const updatedData = [...filteredTableData];
                      const index = updatedData.findIndex((dataItem) => dataItem.unique_id === item.unique_id);
                      updatedData[index] = { ...item, transactionType: e.target.value };
                      setFilteredTableData(updatedData);
                    }}
                  >
                  <option value="" disabled>Select</option>
                    {dropdownOptions.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </select>

                  </div>
                  <div className="medicine-comment">
                    <input
                      type="text"
                      value={item.comment}
                      placeholder="Enter comment"
                      onChange={(e) => {
                        const updatedData = [...filteredTableData];
                        const index = updatedData.findIndex((dataItem) => dataItem.unique_id === item.unique_id);
                        updatedData[index] = { ...item, comment: e.target.value };
                        setFilteredTableData(updatedData);
                      }}
                    />
                  </div>
                  <div className="medicine-submit">
                    <button className="button" type="submit">Submit</button>
                    <button className="button" type="button" onClick={handleDeselectMedicine}>Cancel</button>
                  </div>
                </form>
              )}
            </div>
          )
        ))}
    </div>
  );
}  


export default MedicineNotPresent;
