import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import EditMedicine from './EditMedicine';
import EditMedicineBatch from './EditMedicineBatch';
import EditUsers from './EditUsers';
import EditCarrier from './EditCarrier';
import EditStation from './EditStation';
import EditPouch from './EditPouch';
import EditPouchType from './EditPouchType';
import EditPouchContents from './EditPouchContents';
import EditUserPosition from './EditUserPosition'; 
import EditMedicineBag from './EditMedicineBag';
import EditConsumbaleBatch from './EditConsumableBatch'

function EditPages() {
  const initialActiveTab = localStorage.getItem("activeTab") || 'editMedicine';
  const initialActivePouchTab = localStorage.getItem("activePouchTab") || 'editPouch';
  const initialActiveUserPositionTab = localStorage.getItem("activeUserPositionTab") || 'editUserPosition';

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [activePouchTab, setActivePouchTab] = useState(initialActivePouchTab);
  const [activeUserPositionTab, setActiveUserPositionTab] = useState(initialActiveUserPositionTab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem("activePouchTab", activePouchTab);
  }, [activePouchTab]);

  useEffect(() => {
    localStorage.setItem("activeUserPositionTab", activeUserPositionTab);
  }, [activeUserPositionTab]);

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="main-container">
          <ul className="tabs">
            <li className={`tab ${activeTab === 'editMedicine' ? 'active' : ''}`} onClick={() => setActiveTab('editMedicine')}>Edit Medicine</li>
            <li className={`tab ${activeTab === 'editMedicineBatch' ? 'active' : ''}`} onClick={() => setActiveTab('editMedicineBatch')}>Edit Medicine Batch</li>
            <li className={`tab ${activeTab === 'editConsumbaleBatch' ? 'active' : ''}`} onClick={() => setActiveTab('editConsumbaleBatch')}>Edit Consumable Batch</li>
            <li className={`tab ${activeTab === 'editUsers' ? 'active' : ''}`} onClick={() => setActiveTab('editUsers')}>Edit Users</li>
            <li className={`tab ${activeTab === 'editPouchChoice' ? 'active' : ''}`} onClick={() => setActiveTab('editPouchChoice')}>Edit Pouch</li>
            <li className={`tab ${activeTab === 'editCarrier' ? 'active' : ''}`} onClick={() => setActiveTab('editCarrier')}>Edit Carrier</li>
            <li className={`tab ${activeTab === 'editStation' ? 'active' : ''}`} onClick={() => setActiveTab('editStation')}>Edit Station</li>
            <li className={`tab ${activeTab === 'editUserPosition' ? 'active' : ''}`} onClick={() => setActiveTab('editUserPosition')}>Edit User Position</li>
            <li className={`tab ${activeTab === 'editMedicineBag' ? 'active' : ''}`} onClick={() => setActiveTab('editMedicineBag')}>Edit Medicine Bag</li> {/* Added this line for the new tab */}

          </ul>
          {activeTab === 'editMedicine' && <EditMedicine />}
          {activeTab === 'editMedicineBatch' && <EditMedicineBatch />}
          {activeTab === 'editConsumbaleBatch' && <EditConsumbaleBatch />}          
          {activeTab === 'editUsers' && <EditUsers />}
          {activeTab === 'editCarrier' && <EditCarrier />}
          {activeTab === 'editStation' && <EditStation />}
          {activeTab === 'editUserPosition' && <EditUserPosition />}
          {activeTab === 'editMedicineBag' && <EditMedicineBag />} 
          {activeTab === 'editPouchChoice' && (


            <>
              <ul className="sub-tabs">
                <li className={`sub-tab ${activePouchTab === 'editPouch' ? 'active' : ''}`} onClick={() => setActivePouchTab('editPouch')}>Edit Pouch</li>
                <li className={`sub-tab ${activePouchTab === 'editPouchType' ? 'active' : ''}`} onClick={() => setActivePouchTab('editPouchType')}>Edit Pouch Type</li>
                <li className={`sub-tab ${activePouchTab === 'editPouchContents' ? 'active' : ''}`} onClick={() => setActivePouchTab('editPouchContents')}>Edit Pouch Contents</li>
              </ul>
              {activePouchTab === 'editPouch' && <EditPouch />}
              {activePouchTab === 'editPouchType' && <EditPouchType />}
              {activePouchTab === 'editPouchContents' && <EditPouchContents />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditPages;
