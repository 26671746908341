// Unpacking.js
import React, { useState } from 'react';
import UnpackingHeader from '../components/Unpacking/UnpackingHeader'; // Import the new component
import PouchNumberInput from '../components/Packing/PouchNumberInput';
import PackButtonCheckUnpack from '../components/Unpacking/PackButtonCheckUnpack';
import NSPouchNumberInput from '../components/Packing/NSPouchNumberInput';
import Header from '../components/header';

import { useSelector } from 'react-redux';
import '../static/CSS/packing-app.css';

const Unpacking = () => {
  const token = localStorage.getItem('token');
  const [showBarcodeInput, setShowBarcodeInput] = useState(true);

  return (
    <div className="packing-app">
    <Header />
    <div className="unpacking-app">
      <UnpackingHeader token={token} showBarcodeInput={showBarcodeInput} setShowBarcodeInput={setShowBarcodeInput} />
      <div className="content-container">
        <div className="unpacking-main-content">
          {showBarcodeInput ? (
            <PouchNumberInput token={token} />
          ) : (
            <NSPouchNumberInput token={token} />
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Unpacking;