import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';

const PouchForm = ({ token, selectedPouch }) => {
  const [pouchNumber, setPouchNumber] = useState('');
  const [pouchType, setPouchType] = useState('');
  const [pouchBarcode, setPouchBarcode] = useState('');
  const [selectedPouchId, setSelectedPouchId] = useState(null);

  useEffect(() => {
    fetchPouchTypes();
    if (selectedPouch) {
      setPouchNumber(selectedPouch.pouch_number);
      setPouchType(selectedPouch.pouch_type);
      setPouchBarcode(selectedPouch.pouch_barcode);
      setSelectedPouchId(selectedPouch.pouch_barcode);
    }
  }, [selectedPouch]);

  const handleGenerateBarcode = async () => {
    try {
      const response = await fetch('/api/generate_unique_barcode/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setPouchBarcode(data.unique_barcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Create Pouch
      const pouchResponse = await fetch('/api/Pouch/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pouch_number: pouchNumber,
          pouch_type: pouchType,
          pouch_barcode: pouchBarcode,
        }),
      });
  
      if (pouchResponse.ok) {
        const pouchData = await pouchResponse.json();
        console.log('Pouch created:', pouchData);
  
        // Create PouchStatus
        const pouchStatusResponse = await fetch('/api/PouchStatus/create/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            pouch_barcode: pouchBarcode,
            status: "Unpacked",
          }),
        });
  
        if (pouchStatusResponse.ok) {
          const pouchStatusData = await pouchStatusResponse.json();
          console.log('PouchStatus created:', pouchStatusData);
          window.location.reload();
        } else {
          console.error('Error creating PouchStatus:', pouchStatusResponse.statusText);
        }
  
      } else {
        console.error('Error creating pouch:', pouchResponse.statusText);
      }
    } catch (error) {
      console.error('Error creating pouch:', error);
    }
  };

  const handleRefresh = () => {
    setPouchNumber('');
    setPouchType('');
    setPouchBarcode('');
    setSelectedPouchId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete pouch with barcode ${pouchBarcode}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      // Check if pouch_barcode has changed before updating PouchStatus
      const barcodeChanged = selectedPouchId !== pouchBarcode;
  
      // Update Pouch
      const pouchResponse = await fetch(`/api/Pouch/update/${selectedPouchId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pouch_number: pouchNumber,
          pouch_type: pouchType,
          pouch_barcode: pouchBarcode,
        }),
      });
  
      if (pouchResponse.ok) {
        const pouchData = await pouchResponse.json();
        console.log('Pouch updated:', pouchData);
  
        // Only update PouchStatus if the barcode has changed
        if (barcodeChanged) {
          const pouchStatusResponse = await fetch(`/api/PouchStatus/update/${selectedPouchId}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              pouch_barcode: pouchBarcode,
            }),
          });
  
          if (pouchStatusResponse.ok) {
            const pouchStatusData = await pouchStatusResponse.json();
            console.log('PouchStatus updated:', pouchStatusData);
          } else {
            console.error('Error updating PouchStatus:', pouchStatusResponse.statusText);
          }
        }
  
        window.location.reload();
      } else {
        console.error('Error updating pouch:', pouchResponse.statusText);
      }
    } catch (error) {
      console.error('Error updating pouch:', error);
    }
  };
  

  const handleDelete = async () => {
    try {
      // Delete Pouch
      const pouchResponse = await fetch(`/api/Pouch/delete/${selectedPouchId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (pouchResponse.ok) {
        console.log('Pouch deleted');
  
        // Delete PouchStatus
        const pouchStatusResponse = await fetch(`/api/PouchStatus/delete/${selectedPouchId}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (pouchStatusResponse.ok) {
          console.log('PouchStatus deleted');
          window.location.reload();
        } else {
          console.error('Error deleting PouchStatus:', pouchStatusResponse.statusText);
        }
  
      } else {
        console.error('Error deleting pouch:', pouchResponse.statusText);
      }
    } catch (error) {
      console.error('Error deleting pouch:', error);
    }
  };
  

  const [pouchTypes, setPouchTypes] = useState([]);
  const fetchPouchTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      const response = await fetch('/api/PouchTypeName/', requestOptions); 
      if (response.ok) {
        const data = await response.json();
        setPouchTypes(data);
      } else {
        console.error('Error fetching pouch types:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching pouch types:', error);
    }
  };


  const questions = [
    {
      text: 'Pouch Number:',
      value: pouchNumber,
      handleChange: (e) => setPouchNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Pouch Type:',
      value: pouchType,
      handleChange: (e) => setPouchType(e.target.value),
      options: pouchTypes.map((type) => ({ value: type.pouch_type_name, label: type.pouch_type_name })),
      type: 'select',
      styled: true,
    },
    {
      text: 'Pouch Barcode:',
      value: pouchBarcode,
      handleChange: (e) => setPouchBarcode(e.target.value),
      type: 'text',
      isLocked: true 
    },

      ...(!pouchBarcode ? [{
        text: 'Generate Barcode',
        type: 'generateBarcode'
      }] : [])
  ];

  return (
    <DynamicForm
      title="Add New Pouch"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      handleGenerateBarcode={handleGenerateBarcode}
      selectedId={selectedPouchId}
      setDrugBarcode={setPouchBarcode}
    />
  );
};

export default PouchForm;

