import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Page, Document, PDFDownloadLink, Image, View, Text } from '@react-pdf/renderer';

const BarcodeGenerator = ({ token }) => {
    const [entities, setEntities] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([null]);
    const [barcodeImages, setBarcodeImages] = useState([null]);
    const [barcodeAmount, setBarcodeAmount] = useState(1);
    const [barcodeSize, setBarcodeSize] = useState({ width: 72, height: 85 });
    const [error, setError] = useState(null); 
    const [fileName, setFileName] = useState("Barcodes");
    const [showFormatForm, setShowFormatForm] = useState(false); // new state variable
    const [displayTypes, setDisplayTypes] = useState(['Both']); // new state variable
    const [qrCodeImages, setQrCodeImages] = useState([null]); // new state variable
    const [qrCodeSize, setQrCodeSize] = useState({ width: 50, height: 50 });
    const [pdfFormat, setPdfFormat] = useState('A4 (80)');


    // PDF format options
    const pdfFormatOptions = [
        { label: 'A4 (270)', value: 'A4 (270)' },        
        { label: 'A4 (189)', value: 'A4 (189)' },      
        { label: 'A4 (80)', value: 'A4 (80)' },
        { label: 'A4 (65)', value: 'A4 (65)' },
        { label: 'A4 (40)', value: 'A4 (40)' },
        { label: 'A4 (21)', value: 'A4 (21)' },
    ];
    const endpoints = [
        { name: 'Carrier', path: 'api/Carrier/', itemKey: 'carrier_number', barcodeKey: 'carrier_barcode' },
        { name: 'Medicine Batch', path: 'api/MedicineBatch/', itemKey: 'medicine_name', barcodeKey: 'drug_barcode' },
        { name: 'Pouch', path: 'api/Pouch/', itemKey: 'pouch_number', barcodeKey: 'pouch_barcode' },
        { name: 'Station', path: 'api/Station/', itemKey: 'station_name', barcodeKey: 'station_barcode' },
        { name: 'Medicine Bag', path: 'api/MedicineBag/', itemKey: 'medicine_bag_number', barcodeKey: 'medicine_bag_barcode' },
        { name: 'User', path: 'api/User/', itemKey: 'name', barcodeKey: 'user_barcode' },
        { name: 'Consumables', path: 'api/ConsumableBatch/', itemKey: 'consumable_name', barcodeKey: 'consumable_barcode' },

    ];

    const handleDisplayTypeChange = (selectedOption, index) => {
        setDisplayTypes(oldTypes => {
            const newTypes = [...oldTypes];
            newTypes[index] = selectedOption.value;
            return newTypes;
        });
    };

    const displayTypeOptions = [
        { label: 'Just Barcode', value: 'Barcode' },
        { label: 'Just QR Code', value: 'QR Code' },
        { label: 'Both', value: 'Both' }
    ];

    const fetchEntities = async () => {
        setEntities(endpoints.map(endpoint => ({ label: endpoint.name, value: endpoint })));
    };

    const fetchItems = async (endpoint) => {
        try {
            const response = await axios.get(endpoint.path, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setItems(response.data.map(item => ({ label: item[endpoint.itemKey], value: item })));
        } catch (error) {
            console.error("Error fetching items: ", error);
        }
    };

    useEffect(() => {
        fetchEntities();
    }, []);

    const handleEntitySelection = (selectedOption) => {
        setSelectedEntity(selectedOption);
        fetchItems(selectedOption.value);
    };

    const handleItemSelection = async (selectedOption, index) => {
        setSelectedItems(oldItems => {
            const newItems = [...oldItems];
            newItems[index] = selectedOption;
            return newItems;
        });
    
        const barcodeValue = selectedOption.value[selectedEntity.value.barcodeKey];
        if (barcodeValue) {
            // Generate Barcode if the selected type is 'Both' or 'Barcode'
            if (displayTypes[index] === 'Both' || displayTypes[index] === 'Barcode') {
                try {
                    const barcodeResponse = await axios.get(`/api/generate_barcode/${barcodeValue}/`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    
                    setBarcodeImages(oldImages => {
                        const newImages = [...oldImages];
                        newImages[index] = barcodeResponse.data.barcode_image;
                        return newImages;
                    });
                } catch (error) {
                    console.error("Error generating barcode: ", error);
                }
            }
    
            // Generate QR Code if the selected type is 'Both' or 'QR Code'
            if (displayTypes[index] === 'Both' || displayTypes[index] === 'QR Code') {
                try {
                    const qrCodeResponse = await axios.get(`/api/generate_qr_image/${barcodeValue}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    console.log("QR Code Response Data:", qrCodeResponse.data);  // Log API response
    
                    const base64Image = `data:image/png;base64,${qrCodeResponse.data.qr_code_image}`;
                    console.log("Final Base64 Image URL:", base64Image);  // Log final base64 URL
    
                    setQrCodeImages(oldImages => {
                        const newImages = [...oldImages];
                        newImages[index] = base64Image;
                        return newImages;
                    });
                } catch (error) {
                    console.error("Error generating QR code: ", error);
                }
            }
        } else {
            console.log("Selected item has no barcode");
        }
 
    
        // Calculate the total width and height of the barcodes
        const totalWidth = barcodeSize.width * Math.floor(595 / barcodeSize.width);
        const totalHeight = barcodeSize.height * Math.ceil(barcodeAmount / Math.floor(595 / barcodeSize.width));
    
        if (totalWidth > 595 || totalHeight > 842) {
            setError('The specified number of barcodes of the given size will not fit on an A4 page.');
        } else {
            setError(null);
        }
    };
    

    const handleAddDifferentBarcode = () => {
        setSelectedItems(oldItems => [...oldItems, null]);
        setBarcodeImages(oldImages => [...oldImages, null]);
        setDisplayTypes(oldTypes => [...oldTypes, 'Both']); // add default display type for the new item
        setQrCodeImages(oldImages => [...oldImages, null]); // add placeholder for new QR code image
    };

    const BarcodePDF = () => {
        const mmToPoints = mm => mm * (72 / 25.4);
        const pageWidth = mmToPoints(210); 
        const pageHeight = mmToPoints(297); 
    
        let rows, columns, itemWidth, itemHeight, fontSize;
        switch (pdfFormat) {
            case 'A4 (80)':
                rows = 16; columns = 5;
                fontSize = 10; 
                break;
            case 'A4 (40)':
                rows = 10; columns = 4;
                break;
            case 'A4 (21)':
                rows = 7; columns = 3;
                break;
            case 'A4 (65)':
                rows = 13; columns = 5;
                break;
            case 'A4 (189)':
                rows = 27; columns = 7;
                fontSize = 10; 
                break;
            case 'A4 (270)':
                rows = 27; columns = 10;
                fontSize = 6; 
                break;
            default:
                rows = 16; columns = 5;
        }
    
        // Calculate item width and height based on page dimensions and number of rows and columns
        itemWidth = pageWidth / columns;
        itemHeight = pageHeight / rows;
    
   
  // Create a separate page for each selected item
        return (
            <Document>
                {selectedItems.map((selectedItem, itemIndex) => {
                    // Flatten barcodes for the current item
                    const flattenedBarcodes = Array.from({ length: rows * columns }, () => ({
                        image: barcodeImages[itemIndex],
                        qrImage: qrCodeImages[itemIndex],
                        label: selectedItem ? selectedItem.label : '',
                        type: displayTypes[itemIndex]
                    }));

                    return (
                        <Page key={itemIndex} size="A4" style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            {flattenedBarcodes.map((barcode, index) => (
                                <View key={index} style={{
                                    width: itemWidth,
                                    height: itemHeight,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '4pt'
                                }}>
                                    { (barcode.type === 'Both' || barcode.type === 'QR Code') &&
                                        <Image style={{ width: Math.min(itemWidth, itemHeight) * 0.8, height: Math.min(itemWidth, itemHeight) * 0.8 }} src={barcode.qrImage} />
                                    }
                                    { (barcode.type === 'Both' || barcode.type === 'Barcode') &&
                                        <Image style={{ width: Math.min(itemWidth, itemHeight) * 0.8, height: Math.min(itemWidth, itemHeight) * 0.8 }} src={barcode.image} />
                                    }
                                    <Text style={{ fontSize, marginTop: '2pt', textAlign: 'center' }}>
                                        {barcode.label}
                                    </Text>
                                </View>
                            ))}
                        </Page>
                    );
                })}
            </Document>
        );
    
    };
    
    
    
    
    
    
    
    
    return (
        <div className="card">
            <h2 className="card-header">Barcode Generator</h2>
            <div className="card-body">
                <Select options={entities} onChange={handleEntitySelection} />
                {selectedEntity && selectedItems.map((item, index) => (
                    <div key={index}>
                        <Select options={items} value={item} onChange={option => handleItemSelection(option, index)} />
                        <Select 
                            options={displayTypeOptions} 
                            defaultValue={displayTypeOptions[2]} 
                            onChange={option => handleDisplayTypeChange(option, index)} 
                        />
                        {/* Additional Select component for choosing PDF format */}
                        <Select 
                            options={pdfFormatOptions}
                            value={pdfFormatOptions.find(option => option.value === pdfFormat)}
                            onChange={option => setPdfFormat(option.value)}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {displayTypes[index] === 'Both' && barcodeImages[index] && <img src={`data:image/png;base64,${barcodeImages[index]}`} alt="Barcode" />}
                            {displayTypes[index] === 'Both' && qrCodeImages[index] && <img src={qrCodeImages[index]} alt="QR Code" style={{ width: '100px', height: '100px' }} />}
                            {displayTypes[index] === 'Barcode' && barcodeImages[index] && <img src={`data:image/png;base64,${barcodeImages[index]}`} alt="Barcode" />}
                            {displayTypes[index] === 'QR Code' && qrCodeImages[index] && <img src={qrCodeImages[index]} alt="QR Code" style={{ width: '100px', height: '100px' }} />}
                        </div>
                    </div>
                ))}
                <button onClick={handleAddDifferentBarcode} className='button'>Add Different Barcode</button>
                <button onClick={() => setShowFormatForm(!showFormatForm)} className='button'>
                    {showFormatForm ? 'Hide Format Barcodes' : 'Format Barcodes'}
                </button>
                {showFormatForm && (
                    <>
                        <div>
                            <label for="amount-input">Amount of Barcodes: </label>
                            <input id="amount-input" type="number" min="1" value={barcodeAmount} onChange={e => setBarcodeAmount(e.target.value)} />
                        </div>
                         <div>
                            <label htmlFor="file-name-input">File Name: </label>
                            <input id="file-name-input" type="text" value={fileName} onChange={e => setFileName(e.target.value)} />
                        </div>
                    </>
                )}

                <PDFDownloadLink document={<BarcodePDF />} fileName={`${fileName}.pdf`}>
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
                </PDFDownloadLink>
            </div>
        </div>
    );
};

export default BarcodeGenerator;
