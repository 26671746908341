// src/components/LandingPageHeader.js

import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../../static/CSS/LandingPageHeader.css';

const LandingPageHeader = () => {
  return (
    <header className="landing-page-header">
      <div className="header-content">
        <Link to="/" className="home-button">Home</Link>  {/* Home button */}
        <h1>Precise PouchTrack</h1>
        <Link to="/login" className="login-button">Login</Link>
      </div>
    </header>
  );
};

export default LandingPageHeader;

