import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Textinput from '../Styling components/Textinput'


const MedicineUpload = ({ token, onReload }) => {
  const [pouchNumber, setPouchNumber] = useState('');
  const pouchType = useSelector((state) => state.user.pouchType);
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  const name = useSelector((state) => state.user.name)
  const [medicineBarcode, setMedicineBarcode] = useState('');
  const [medicineName, setMedicineName] = useState('');
  const [amount, setAmount] = useState('');
  const pouch_barcode = pouchBarcode;
  const user_barcode = userBarcode;
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [medicineExists, setMedicineExists] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [forceAdd, setForceAdd] = useState(false);
  const [pouchAmount, setPouchAmount] = useState(null);
  const [stockAmount, setStockAmount] = useState(null);
  const [isStockAvailable, setIsStockAvailable] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const [actualAmount, setActualAmount] = useState('');
  const [showPopup, setShowPopup] = useState(false);


  const handleBarcodeScan = (data) => {
    setMedicineBarcode(data);
  };

  const isExpired = (expiryDate) => {
    const currentDate = new Date();
    const parsedExpiryDate = new Date(expiryDate);
    return parsedExpiryDate <= currentDate;
  };
  
  const isExpiringSoon = (expiryDate) => {
    const currentDate = new Date();
    const oneMonthLater = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    const parsedExpiryDate = new Date(expiryDate);
    return parsedExpiryDate <= oneMonthLater;
  };


  useEffect(() => {
    const fetchStockAmount = async () => {
      try {
        const response = await axios.get(`/api/stores_levels/${medicineBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data[0].Total_Amount) {
          setStockAmount(response.data[0].Total_Amount);
          setIsStockAvailable(true);
        } else {
          setStockAmount(0);
          setIsStockAvailable(false);
        }
      } catch (error) {
        console.error('Error fetching stock amount:', error);
      }
    };
  
    if (medicineBarcode) {
      fetchStockAmount();
    } else {
      setStockAmount(null);
    }
  }, [medicineBarcode, token, dataChanged]);
  

  useEffect(() => {
    const fetchRemainingAmount = async () => {
      try {
        const response = await axios.get(
          `/api/pouch_remaining_data/${pouch_barcode}/${pouchType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.length > 0) {
          const medicineData = response.data.find(
            (item) => item.Medicine_Name === medicineName
          );
          if (medicineData) {
            setRemainingAmount(medicineData.Remaining_Amount === null ? 0 : medicineData.Remaining_Amount);
            setMedicineExists(true);
          } else {
            setRemainingAmount(null);
            setMedicineExists(false);
          }
        } else {
          setRemainingAmount(null);
          setMedicineExists(false);
        }
        
      } catch (error) {
        console.error('Error fetching remaining amount:', error);
      }
    };
  
    if (medicineName) {
      fetchRemainingAmount();
    } else {
      setRemainingAmount(null);
    }
  }, [medicineName, pouchNumber, pouchType, token, dataChanged]);

  useEffect(() => {
    const fetchPouchNumber = async () => {
      try {
        const response = await axios.get(`/api/Pouch/${pouchNumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.pouch_number) {
          setPouchNumber(response.data.pouch_number);
          localStorage.setItem('pouch_type', response.data.pouch_type);
        } else {
          setPouchNumber('');
        }
      } catch (error) {
        console.error('Error fetching pouch number:', error);
      }
    };
    
    if (pouchNumber) {
      fetchPouchNumber();
    } else {
      setPouchNumber('');
    }
  }, [pouch_barcode, token, dataChanged]);

  useEffect(() => {
    const fetchMedicineName = async () => {
      try {
        let response = await axios.get(`/api/MedicineBatch/${medicineBarcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // If not found in MedicineBatch, try ConsumableBatch
        if (!response.data || !response.data.medicine_name) {
          response = await axios.get(`/api/ConsumableBatch/${medicineBarcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        // Use the correct field name based on the response
        const productName = response.data.medicine_name || response.data.consumable_name;
        if (productName) {
          setMedicineName(productName);
        } else {
          setMedicineName('');
        }
      } catch (error) {
        console.error('Error fetching product name:', error);
      }
    };

    if (medicineBarcode) {
      fetchMedicineName();
    } else {
      setMedicineName('');
    }
  }, [medicineBarcode, token, dataChanged]);


  useEffect(() => {
    const fetchPouchAmount = async () => {
      try {
        const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const pouchContent = response.data.find(item => item.Medicine_Name === medicineBarcode);
  
        if (pouchContent) {
          setPouchAmount(pouchContent.Total_Amount);
        } else {
          setPouchAmount(null);
        }
      } catch (error) {
        console.error('Error fetching pouch amount:', error);
      }
    };
  
    fetchPouchAmount();
  }, [pouchBarcode, token, dataChanged]);
  

  const handleAddAnyway = () => {
    setForceAdd(true);
    setShowError(false);
  };
  
  const handleDontAdd = () => {
    setForceAdd(false);
    setShowError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
  
    try {
      let selectedMedicineResponse = await axios.get(`/api/MedicineBatch/${medicineBarcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // If not found in MedicineBatch, try ConsumableBatch
      if (!selectedMedicineResponse.data || !selectedMedicineResponse.data.unique_id) {
        selectedMedicineResponse = await axios.get(`/api/ConsumableBatch/${medicineBarcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }

      const selectedMedicine = selectedMedicineResponse.data;
  
      // Check if the medicine is expired
      if (selectedMedicine && isExpired(selectedMedicine.expiry_date)) {
        alert('This product is expired. Please pick another batch.');
        setMedicineBarcode('');
        return;
      }
  
      // Check if the medicine is soon to expire
      if (selectedMedicine && isExpiringSoon(selectedMedicine.expiry_date)) {
        const userConfirmed = window.confirm('This product is soon to expire. Are you sure you want to add it?');
        if (!userConfirmed) {
          setMedicineBarcode('');
          return;
        }
      }
  
      // The below code replaces the popup logic
      setDataChanged(false);
  
      if (!isStockAvailable) {
        alert('Not in stock');
        return;
      }
  
      if (!medicineExists && !forceAdd) {
        const userConfirmed = window.confirm('Wrong drug. Do you want to add anyway?');
        if (!userConfirmed) {
          return;
        }
      }
  
      if (amount > remainingAmount && !forceAdd) {
        const userConfirmed = window.confirm('Too many added. Do you want to add anyway?');
        if (!userConfirmed) {
          return;
        }
      }
  
      const medicineId = selectedMedicine.unique_id; // Assuming the field is named 'unique_id'
  
      if (!medicineId) {
        alert("Could not find the medicine batch.");
        return;
      }
  
      for (let i = 0; i < amount; i++) {
        await axios.post('/api/Pouch_history/', {
          station_barcode: stationBarcode,
          user_packed: user_barcode,
          drug_barcode: medicineBarcode,
          pouch_barcode: pouchBarcode,
          amount: 1,
          datetime_packed: new Date().toISOString(),
          status: 'Packed',
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
  
      await axios.post('/api/StockHoldingHistory/create/', {
        location: stationBarcode,
        user_barcode: user_barcode,
        drug_barcode: medicineBarcode,
        transaction: 'Stock to Pouch',
        amount: amount,
        datetime_transaction: new Date().toISOString(),
        comments: `Packed by ${name}`,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // New call to create entry in MedicineInPouch
      await axios.post('/api/MedicineInPouch/create/', {
        drug_barcode: medicineBarcode,
        pouch_barcode: pouchBarcode,
        amount: amount,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Medicine uploaded successfully');
      setDataChanged(true);
      setForceAdd(false); 
      onReload();
    
      // Clear the form
      setMedicineBarcode('');
      setMedicineName('');
      setAmount('');
      // If you have these functions, uncomment them to reset other fields
      // setSelectedBatchNumber('');
      // setUniqueId(''); 
      // setSearchTerm('');
      // setSelectedValue("");
  
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      alert('Error uploading medicine');
    }
  };
  
   
  const questions = [
    {
      text: 'Medicine Barcode:',
      type: 'text',
      value: medicineBarcode,
      handleChange: (e) => setMedicineBarcode(e.target.value),
    },

    // Add other input fields as needed...
  ];

  return (
    <div className="medicine-upload-card">
      <h2 className="medicine-upload-header">Medicine Upload</h2>
      <div className="medicine-upload-body">
        <form onSubmit={handleSubmit} className="medicine-upload-form">
          <Textinput questions={questions} />
  
          {medicineName && (
          <>
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Medicine Name:</span>
              <span className="medicine-upload-value">{medicineName}</span>
            </div>
            
            <div className="medicine-upload-item">
              <label htmlFor="amount" className="medicine-upload-label">
                Amount:
              </label>
              <div className="amount-input">
                <input 
                  type="number" 
                  id="amount" 
                  name="amount" 
                  placeholder="Enter amount" 
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

          {showError && errorMessage && (
            <div className="medicine-upload-card">
              <div className="error-modal">
                <p>{errorMessage}</p>
                <button onClick={handleAddAnyway}>Add anyway</button>
                <button onClick={handleDontAdd}>Don't add</button>
              </div>
            </div>
          )}
  
          {stockAmount !== null && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Stock Amount:</span>
              <span className="medicine-upload-value">{stockAmount}</span>
            </div>
          )}
  
          {!isStockAvailable && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-error">Not in stock</span>
            </div>
          )}
  
          {pouchAmount !== null && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Amount in Pouch:</span>
              <span className="medicine-upload-value">{pouchAmount}</span>
            </div>
          )}
  
          {pouchNumber !== null && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Pouch Number:</span>
              <span className="medicine-upload-value">{pouchNumber}</span>
            </div>
          )}
  
          {medicineName && (remainingAmount !== null ? (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Amount Remaining:</span>
              <span className="medicine-upload-value">{remainingAmount}</span>
            </div>
          ) : (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Incorrect Drug:</span>
              <span className="medicine-upload-value">Not in the pouch type</span>
            </div>
          ))}
  
          <div className="medicine-upload-item">
            <button className="medicine-upload-button" type="submit">Submit</button>
          </div>
        </form>


  
      </div>
    </div>
  );
  
};

export default MedicineUpload;