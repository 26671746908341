import React, { useState } from 'react';
import Header from '../components/header';
import MedicineBagUnpack from '../components/Medicine_Bag/MedicineBagUnpack';
import NSMedicineBagUnpack from '../components/Medicine_Bag/NSMedicineBagUnpack';  // Import the NSMedicineBagUnpack component
import MedicineBagTable from '../components/Medicine_Bag/MedicineBagTable';

const token = localStorage.getItem("token");

const MedicineBagUnpackPage = () => {
    const [isShowingNSMedicineBagUnpack, setIsShowingNSMedicineBagUnpack] = useState(false);  // State variable to determine which component to show

    return (
        <div className='main-content'>
            <Header token={token}/>
            <button className="button" onClick={() => setIsShowingNSMedicineBagUnpack(!isShowingNSMedicineBagUnpack)}>
                {isShowingNSMedicineBagUnpack ? "Switch to Barcodes" : "Switch to Manual"}
            </button>
            
            {isShowingNSMedicineBagUnpack ? <NSMedicineBagUnpack token={token}/> : <MedicineBagUnpack token={token}/>}
            <MedicineBagTable token={token}/>

        </div>
    );
};

export default MedicineBagUnpackPage;
