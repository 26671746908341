// QA.js
import React, { useState } from 'react';
import QAHeader from '../components/Quality Assessment/QAHeader'; // Import the QAHeader component
import QualityAssessment from '../components/Quality Assessment/QualityAssessment';
import PouchNumberInput from '../components/Packing/PouchNumberInput'; // Make sure this component is imported correctly
import NSPouchNumberInput from '../components/Packing/NSPouchNumberInput'; // Make sure this component is imported correctly
import ConfirmationButton from '../components/Quality Assessment/ConfirmationButton';
import { useSelector } from 'react-redux';
import '../static/CSS/packing-app.css'; // Ensure this CSS file contains the necessary styles
import Header from '../components/header';

const QA = () => {
  const token = localStorage.getItem("token");
  const [showBarcodeInput, setShowBarcodeInput] = useState(true);
  const [showPouchForm, setShowPouchForm] = useState(false); // Define the state here
  const userName = useSelector((state) => state.user.name);

  // Additional state for Quality Assessment
  const [checkedItems, setCheckedItems] = useState({});
  const [pouchContents, setPouchContents] = useState([]);

  // Function to toggle the pouch form visibility
  const togglePouchForm = () => {
    setShowPouchForm(!showPouchForm); // Define the function here
  };

  return (
    <div className="packing-app">
    <Header />
    <div className="qa-app">
      <QAHeader
        token={token}
        togglePouchForm={togglePouchForm} // Pass the function to the QAHeader component
        showBarcodeInput={showBarcodeInput}
        setShowBarcodeInput={setShowBarcodeInput}
      />
      {showPouchForm && ( // Use the state here
        showBarcodeInput ? 
        <PouchNumberInput token={token} /> : // Ensure this component is imported
        <NSPouchNumberInput token={token} /> // Ensure this component is imported
      )}

      <div className="qa-assessment-container">
        <QualityAssessment 
          token={token} 
          checkedItems={checkedItems} 
          setCheckedItems={setCheckedItems} 
          pouchContents={pouchContents} 
          setPouchContents={setPouchContents} 
        />
        <ConfirmationButton 
          token={token} 
          checkedItems={checkedItems} 
          pouchContents={pouchContents} 
        />
      </div>
    </div>
    </div>
  );
};

export default QA;
