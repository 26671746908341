import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const NSMedicineBagStore = ({ token }) => {
    const [medicineBags, setMedicineBags] = useState([]);
    const [filteredMedicineBags, setFilteredMedicineBags] = useState([]);
    const [selectedMedicineBagBarcode, setSelectedMedicineBagBarcode] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const fetchMedicineBags = async () => {
            try {
                const response = await axios.get('/api/packed_pouches_in_medicine_bag/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    const uniqueMedicineBags = Array.from(new Set(response.data.map(item => ({
                        Medicine_Bag_Barcode: item.Medicine_Bag_Barcode,
                        Medicine_Bag_Number: item.Medicine_Bag_Number
                    }))));
                    setMedicineBags(uniqueMedicineBags);
                    setFilteredMedicineBags(uniqueMedicineBags);
                }
            } catch (error) {
                console.error('Error fetching medicine bags:', error);
            }
        };

        fetchMedicineBags();
    }, [token]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredMedicineBags(medicineBags);
        } else {
            setFilteredMedicineBags(medicineBags.filter(medicineBag => medicineBag.Medicine_Bag_Number.toLowerCase().includes(searchQuery.toLowerCase())));
        }
    }, [searchQuery, medicineBags]);

    const handleSearchEnter = (e) => {
        if (e.key === 'Enter' && filteredMedicineBags.length > 0) {
            setSelectedMedicineBagBarcode(filteredMedicineBags[0].Medicine_Bag_Barcode);
        }
    };

    const storeAll = async () => {
        if (!selectedMedicineBagBarcode) {
            alert('Please select a medicine bag.');
            return;
        }

        const confirmStore = window.confirm(`Are you sure you want to store all pouches of ${selectedMedicineBagBarcode}?`);
        if (!confirmStore) {
            return;
        }

        const dataToUpdate = {
            Station_Stored: user.station_barcode,
            datetime_stored: new Date().toISOString(),
            User_Stored: user.userBarcode,
        };

        try {
            const response = await axios.put('/api/UpdateMedicineBagStore/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert(`Stored at Site ${user.station_barcode}`);
            } else {
                alert('Error storing pouches.');
            }
        } catch (error) {
            console.error('Error storing pouches:', error);
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Store Pouches in Medicine Bag</h2>
                <div className="packing-main-content">
                    <form>
                        <div className="input-group">
                            <label>Search Medicine Bag:</label>
                            <input 
                                type="text"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                onKeyDown={handleSearchEnter}
                            />
                        </div>
                        <div className="input-group">
                            <label>Select Medicine Bag to Store:</label>
                            <select 
                                value={selectedMedicineBagBarcode} 
                                onChange={e => setSelectedMedicineBagBarcode(e.target.value)}
                            >
                                <option value="">Choose Medicine Bag</option>
                                {filteredMedicineBags.map((medicineBag, index) => (
                                    <option key={index} value={medicineBag.Medicine_Bag_Barcode}>
                                        {medicineBag.Medicine_Bag_Number}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button onClick={storeAll} className="card-button">Store All in Selected Medicine Bag</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NSMedicineBagStore;
