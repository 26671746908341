import React, { useState, useEffect } from 'react';
import axios from 'axios';

import DynamicForm from '../DynamicForm/DynamicForm';

const MedicineBagForm = ({ token, selectedMedicineBag }) => {
  const [medicineBagNumber, setMedicineBagNumber] = useState('');
  const [medicineBagType, setMedicineBagType] = useState('');
  const [medicineBagBarcode, setMedicineBagBarcode] = useState('');
  const [selectedMedicineBagId, setSelectedMedicineBagId] = useState(null);
  const [medicineBagTypeOptions, setMedicineBagTypeOptions] = useState([]);


  useEffect(() => {
    if (selectedMedicineBag) {
      setMedicineBagNumber(selectedMedicineBag.medicine_bag_number);
      setMedicineBagType(selectedMedicineBag.medicine_bag_type);
      setMedicineBagBarcode(selectedMedicineBag.medicine_bag_barcode);
      setSelectedMedicineBagId(selectedMedicineBag.medicine_bag_barcode);
    }
  }, [selectedMedicineBag]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Create MedicineBag
      const medicineBagResponse = await fetch('/api/MedicineBags/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          medicine_bag_number: medicineBagNumber,
          medicine_bag_type: medicineBagType,
          medicine_bag_barcode: medicineBagBarcode,
        }),
      });
  
      if (medicineBagResponse.ok) {
        const medicineBagData = await medicineBagResponse.json();
        console.log('Medicine Bag created:', medicineBagData);
  
        // Create MedicineBagStatus
        const medicineBagStatusResponse = await fetch('/api/MedicineBagStatus/create/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            medicine_bag_barcode: medicineBagBarcode,
            status: "Unpacked",
          }),
        });
  
        if (medicineBagStatusResponse.ok) {
          const medicineBagStatusData = await medicineBagStatusResponse.json();
          console.log('MedicineBagStatus created:', medicineBagStatusData);
          window.location.reload();
        } else {
          console.error('Error creating MedicineBagStatus:', medicineBagStatusResponse.statusText);
        }
  
      } else {
        console.error('Error creating medicine bag:', medicineBagResponse.statusText);
      }
    } catch (error) {
      console.error('Error creating medicine bag:', error);
    }
  };
  

  const handleRefresh = () => {
    setMedicineBagNumber('');
    setMedicineBagType('');
    setMedicineBagBarcode('');
    setSelectedMedicineBagId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete Medicine Bag with Barcode: ${medicineBagBarcode}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const barcodeChanged = selectedMedicineBagId !== medicineBagBarcode;
  
      const medicineBagResponse = await fetch(`/api/MedicineBags/update/${selectedMedicineBagId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          medicine_bag_number: medicineBagNumber,
          medicine_bag_type: medicineBagType,
          medicine_bag_barcode: medicineBagBarcode,
        }),
      });
  
      if (medicineBagResponse.ok) {
        const medicineBagData = await medicineBagResponse.json();
        console.log('Medicine Bag updated:', medicineBagData);
  
        if (barcodeChanged) {
          const medicineBagStatusResponse = await fetch(`/api/MedicineBagStatus/update/${selectedMedicineBagId}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              medicine_bag_barcode: medicineBagBarcode,
            }),
          });
  
          if (medicineBagStatusResponse.ok) {
            const medicineBagStatusData = await medicineBagStatusResponse.json();
            console.log('MedicineBagStatus updated:', medicineBagStatusData);
          } else {
            console.error('Error updating MedicineBagStatus:', medicineBagStatusResponse.statusText);
          }
        }
        window.location.reload();
      } else {
        console.error('Error updating medicine bag:', medicineBagResponse.statusText);
      }
    } catch (error) {
      console.error('Error updating medicine bag:', error);
    }
  };
  

  const handleDelete = async () => {
    try {
      const medicineBagResponse = await fetch(`/api/MedicineBags/delete/${selectedMedicineBagId}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (medicineBagResponse.ok) {
        console.log('Medicine Bag deleted');
  
        const medicineBagStatusResponse = await fetch(`/api/MedicineBagStatus/delete/${selectedMedicineBagId}/`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (medicineBagStatusResponse.ok) {
          console.log('MedicineBagStatus deleted');
          window.location.reload();
        } else {
          console.error('Error deleting MedicineBagStatus:', medicineBagStatusResponse.statusText);
        }
  
      } else {
        console.error('Error deleting medicine bag:', medicineBagResponse.statusText);
      }
    } catch (error) {
      console.error('Error deleting medicine bag:', error);
    }
  };
  

  const handleGenerateBarcode = async () => {
    try {
      const response = await fetch('/api/generate_unique_barcode/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setMedicineBagBarcode(data.unique_barcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  useEffect(() => {
    // Fetch dropdown options from API
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('api/dropdown_options/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const medicineBagTypes = response.data.filter(item => item.Dropdown_Name === 'Medicine_Bag_Type');
        setMedicineBagTypeOptions(medicineBagTypes.map(item => item.Dropdown_Options));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };
    
    fetchDropdownOptions();
  }, [token]);

  const questions = [
    {
      text: 'Medicine Bag Number',
      value: medicineBagNumber,
      handleChange: (e) => setMedicineBagNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Medicine Bag Type',
      value: medicineBagType,
      handleChange: (e) => setMedicineBagType(e.target.value),
      type: 'select',
      styled: true,
      options: medicineBagTypeOptions.map((type) => ({ value: type, label: type })),
    },
    {
      text: 'Medicine Bag Barcode',
      value: medicineBagBarcode,
      handleChange: (e) => setMedicineBagBarcode(e.target.value),
      type: 'text',
    },
    {
      type: 'generateBarcode',
      text: 'Generate Barcode',
    }
  ];

  return (
    <div className="form-container">
      <DynamicForm
        title="Add New Medicine Bag"
        questions={questions}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleUpdate={handleUpdate}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleGenerateBarcode={handleGenerateBarcode} 
        setDrugBarcode={setMedicineBagBarcode}  
        selectedId={selectedMedicineBagId}
        showSearchBar={true}
      />
    </div>
  );
};

export default MedicineBagForm;
