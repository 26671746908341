// src/PouchTrackPage.js

import React from 'react';
import '../../static/CSS/LandingPageHeader.css';


const PouchTrackPage = () => {
  return (
    <div className="pouchtrack-page">
      <h1>Precise PouchTrack</h1>
      <p>This Site is being developed</p>
    </div>
  );
}

export default PouchTrackPage;
