import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'; 
import { generateDocument } from './PrintSheet';
import PrintButton from './PrintButton';
import FillTemplateButton from './PrintButton3';


const ConfirmationButton = ({ token, checkedItems, setCheckedItems, pouchContents }) => {
    const userBarcode = useSelector((state) => state.user.userBarcode);
    const [packedName, setPackedName] = useState(null);
    const [canPrint, setCanPrint] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false); 
    const pouchNumber = pouchContents[0]?.Pouch_Number;
    const [pouchTypeData, setPouchTypeData] = useState([]);
    const pouchBarcode = useSelector((state) => state.user.pouchBarcode);

    // Define resetCheckboxes function here
    const resetCheckboxes = () => {
        const newCheckedItems = { ...checkedItems };
        Object.keys(newCheckedItems).forEach(key => {
            newCheckedItems[key] = false;
        });
        setCheckedItems(newCheckedItems);
    };

    useEffect(() => {
        const fetchPouchTypeData = async () => {
            try {
                const response = await axios.get('/api/PouchType/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setPouchTypeData(response.data);
            } catch (error) {
                console.error('Error fetching pouch type data:', error);
            }
        };
    
        fetchPouchTypeData();
    }, [token]);

    const updatePouchHistory = async (uniqueIDs) => {
        for (let uniqueID of uniqueIDs) {
            try {
                const response = await axios.put(`/api/update_pouch_history/${uniqueID}/`, {
                    datetime_qa: new Date().toISOString(),
                    user_qa: userBarcode, 
                    status: "QAed",
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } catch (error) {
                console.error('Error updating pouch history:', error);
            }
        }
    }

    const updatePouchStatus = async () => {
        await axios.put(`/PouchStatus/update/${pouchBarcode}`, {
            QA: 1, 
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });
    };

    const fetchPackedName = async (uniqueID) => {
        try {
            const response = await axios.get(`/api/Name_from_userbarcode/${uniqueID}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPackedName(response.data[0].Name);
        } catch (error) {
            console.error('Error fetching packed name:', error);
        }
    }

    useEffect(() => {
        const allPresentChecked = pouchContents.every(content => 
            Array(content.Amount).fill().every((_, i) => checkedItems[`present-${content.unique_id}-${i}`])
        );
        const anyIncorrectChecked = pouchContents.some((content, i) => checkedItems[`incorrect-${content.unique_id}-${i}`]);
        setCanPrint(allPresentChecked && !anyIncorrectChecked);
    }, [checkedItems, pouchContents]);

    const handleClick = async () => {
        try {
   
            const allPresentChecked = pouchContents.every(content => 
                checkedItems[`present-${content.unique_id}`]
            );
            const anyIncorrectChecked = pouchContents.some((content, i) => checkedItems[`incorrect-${content.unique_id}`]);
            
            if (allPresentChecked && !anyIncorrectChecked) {
                const uniqueIDs = pouchContents
                    .filter(content => checkedItems[`present-${content.unique_id}`])
                    .map(content => content.unique_id);

                await updatePouchHistory(uniqueIDs);
                await updatePouchStatus();
                alert('QA saved');
                resetCheckboxes();
            } else if (anyIncorrectChecked) {
                const incorrectItem = pouchContents.find((content, i) => checkedItems[`incorrect-${content.unique_id}`]);
                if (incorrectItem && !packedName) {
                    await fetchPackedName(incorrectItem.unique_id);
                }
                alert(`This is incorrect. Please return to ${packedName} to unpack and repack.`);
            } else {
            }
        } catch (error) {
            console.error('Error in handleClick:', error);
        }
    }
    
    
    
    
    
    

    const handlePrint = async () => {
        try {
            const pouchResponse = await axios.get(`/api/Pouch/${pouchBarcode}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            let pouchDetails = pouchResponse.data;
            if (!pouchDetails) {
                console.error('Pouch details not found for the given pouch number');
                return;
            }
    
            // Fetch the pouch medicine data
            const medicineResponse = await axios.get(`/api/pouch_medicine_data/${pouchDetails.pouch_barcode}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            const pouchMedicineData = medicineResponse.data;
            if (!pouchMedicineData) {
                console.error('Pouch medicine data not found for the given pouch barcode');
                return;
            }

            // Add the Packed_by and QA_By properties to pouchDetails
            pouchDetails = {
                ...pouchDetails,
                Packer: pouchMedicineData[0].Packed_by,
                QA: pouchMedicineData[0].QA_By,
            };
        
            generateDocument({ pouchDetails, token, pouchTypeData });
        } catch (error) {
            console.error('Error fetching pouch details:', error);
        }
    };
    

    return (
        <div className="card">
            <div className="card-body">
                <div className="account-card-item">
                    <button className="button" onClick={handleClick}>I confirm this is correct</button>
                    <button className="button" onClick={handlePrint}>Print Sheet</button>
                    <PrintButton pouchBarcode={pouchBarcode} token={token} />
                    <FillTemplateButton pouchBarcode={pouchBarcode} token={token} />
                </div>
            </div>
        </div>
    );
}
  
export default ConfirmationButton;
