import React, { useState, useEffect } from 'react';
import MedicineBagForm from "./MedicineBagForm"; 

const MedicineBagTable = ({token}) => {
  let [medicineBags, setMedicineBags] = useState([]);
  const [selectedMedicineBag, setSelectedMedicineBag] = useState(null);

  useEffect(() => {
    getMedicineBags();
  }, []);

  let getMedicineBags = async () => {
    try {
      let response = await fetch('/api/MedicineBag/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      setMedicineBags(data);
    } catch (error) {
      console.error('Error fetching medicine bags:', error);
    }
  };

  const handleRowClick = (medicineBag) => {
    setSelectedMedicineBag(medicineBag);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        <div className="table-list" style={{ flex: 1 }}>
          <div style={{ flex: 1 }}>
            <MedicineBagForm selectedMedicineBag={selectedMedicineBag}  token={token} />
          </div>
          <table>
            <thead>
              <tr>
                <th>Medicine Bag Number</th>
                <th>Medicine Bag Type</th>
                <th>Medicine Bag Barcode</th>
              </tr>
            </thead>
            <tbody>
              {medicineBags.map((medicineBag, index) => (
                <tr key={index} onClick={() => handleRowClick(medicineBag)}>
                  <td>{medicineBag.medicine_bag_number}</td>
                  <td>{medicineBag.medicine_bag_type}</td>
                  <td>{medicineBag.medicine_bag_barcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MedicineBagTable;
