import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import LocationForm from '../components/Station Carrier Inputs/StationInput';
import Carrier_inputs from '../components/Station Carrier Inputs/Carrier_inputs';
import { useSelector } from 'react-redux';

const token = localStorage.getItem("token");

const CarrierLoad = () => {
  const { username, station, pouchNumber, carrier } = useSelector((state) => state.user); // Added carrier

  useEffect(() => {
  }, [username, station, pouchNumber]);

  return (
    <div className='main-content'>
      <Header />
      <LocationForm token={token} /> 

      <Carrier_inputs token={token} /> 
      <div className="button-container"> 
      <div className="selected-carrier-info">
          {carrier ? `Carrier: ${carrier}` : 'No carrier selected'}
      </div>
      <Link to="/CarrierPackPage"><button className='button'>Pack Carrier</button></Link>
      <Link to="/CarrierStorePage"><button className='button'>Store Carrier</button></Link>
      <Link to="/CarrierUnpackPage"><button className='button'>Unpack Carrier</button></Link>
      </div>
    </div>
  );
};

export default CarrierLoad;
