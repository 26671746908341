import React from 'react';
import Textinput1Question from '../Styling components/Textinput1Question';
import StyledDropdown from '../Styling components/StyledDropdown';

const DynamicForm = ({
  title,
  questions,
  handleSubmit,
  handleRefresh,
  handleUpdate,
  handleDeleteConfirmation,
  handleGenerateBarcode,
  selectedId,
  setDrugBarcode, 
  showAddButton = true,         // Default is true
  showRefreshButton = true,     // Default is true
  showUpdateButton = true,      // Default is true
  showDeleteButton = true       // Default is true
}) => {

  return (
    <div className="Form-card">
      <div className="Form-card-body">
        <form onSubmit={handleSubmit} className="Form-form-container">
          <h3 className='Form-Title'>{title}</h3>
          
          {questions.map((question, index) => (
            <div key={index} className="Form-form-row">
              {question.type === 'select' && question.styled ? (
                <StyledDropdown
                  options={question.options}
                  value={question.value || ''}
                  onChange={question.handleChange}
                  placeholder={question.text}
                />
              ) : question.type === 'select' ? (
                <select value={question.value || ''} onChange={question.handleChange} required>
                  {question.options.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                
              ) : question.type === 'textarea' ? (
                
                <>
              <div className="textarea-container">
                <label className="textarea-label">{question.text}</label>
                <textarea
                  className="textarea-box"
                  value={question.value || ''}
                  onChange={question.handleChange}
                  rows={question.rows || 5}
                />
              </div>
              </>
              ) : question.type === 'generateBarcode' ? (
                <button
                  className="button"
                  type="button"
                  onClick={() => handleGenerateBarcode(setDrugBarcode)}
                  disabled={question.isLocked || false}
                >
                  {question.text}
                </button>
              ) : (
                <Textinput1Question 
                  question={question} 
                  isLocked={question.isLocked || false}
                />
              )}
            </div>
          ))}
          <div className="Form-form-row-button-container">
              {showAddButton && <button className="button" type="submit">Add</button>}
              {showRefreshButton && <button className="button" type="button" onClick={handleRefresh}>Refresh</button>}
              {showUpdateButton && <button className="button" type="button" onClick={handleUpdate} disabled={!selectedId}>Update</button>}
              {showDeleteButton && <button className="button" type="button" onClick={handleDeleteConfirmation} disabled={!selectedId}>Delete</button>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DynamicForm;
