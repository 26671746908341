import React from 'react';
import axios from 'axios';

const PrintButton = ({pouchBarcode, token}) => {

    const Generatedocument = async () => {
        try {
          const response = await axios.get(
            `/api/generate_pouch_paperwork/${pouchBarcode}/`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'blob',  // Tell axios to download the file
            }
          );
      
          const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
      
          // This will prompt the user to save the file with a specific name.
          link.setAttribute('download', 'ChooseYourFileName.docx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);  // Free up memory
      
          alert('Word document successfully generated!');
        } catch (error) {
          console.error('An error occurred while generating the Word document:', error);
          alert('Failed to generate Word document');
        }
      };
      

  return (
    <button className="button" onClick={Generatedocument}>
      Print 2.0
    </button>
  );
};

export default PrintButton;