// src/pages/LoginForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../Redux/slices/userSlice';
import { setPermissions } from '../Redux/slices/permissionSlice';  // <-- Import this
import { useNavigate } from 'react-router-dom';
import "../static/CSS/LoginForm.css"
import ForgotPasswordModal from '../components/Forgotpassword/ForgotPasswordModal'; // Import the Forgot Password Modal
import { Link } from 'react-router-dom';


const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUserByUsername = async (username) => {
    try {
      const response = await axios.get(`/api/User/${username}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        const userData = response.data[0];
        userData.username = username; 
        dispatch(setUser(userData));
      }
    } catch (error) {
      console.error('Error fetching user by username:', error);
    }
  };

  const fetchUserPermissions = async () => {
    try {
      const response = await axios.get('/api/get_user_permissions/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        dispatch(setPermissions(response.data.permissions));
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };


  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login/', {
        username,
        password,
      });
  
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        await fetchUserByUsername(username);
        await fetchUserPermissions();  // <-- Fetch permissions here
        navigate('/home');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('Login failed');
    }
  };
  

  return (
    <div className="Page-container">
      <div className="login-form-container">
        <form className="login-form" onSubmit={loginUser}>
          <h2>Login</h2>
          {loginError && <div className="error">{loginError}</div>}
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <Link to="/forgot-username">Forgot Username?</Link> {/* Add this line */}
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button  type="submit">Login</button>
        </form>
        <div className="forgot-password">
          <ForgotPasswordModal /> {/* Add Forgot Password Modal here */}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
