// src/components/StationInput.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setStation, setStationBarcode } from '../../Redux/slices/userSlice';
import StyledDropdown from "../Styling components/StyledDropdown"


const StationInput = ({token}) => {
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get('/api/Station/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setStations(response.data);
        }
      } catch (error) {
        console.error('Error fetching stations:', error);
      }
    };

    fetchStations();
  }, []);

  const handleStationChange = (e) => {
    setSelectedStation(e.target.value);
  };

  const handleStationSubmit = (e) => {
    e.preventDefault();
    const selectedStationObject = stations.find(station => station.station_name === selectedStation);
    if (selectedStationObject) {
      dispatch(setStation(selectedStationObject.station_name));
      dispatch(setStationBarcode(selectedStationObject.station_barcode));
    }
  };

  const dropdownOptions = stations.map(station => ({
    value: station.station_name,
    label: station.station_name,
  }));

  return (
    <div className="packing-app">
      <div className="packing-input-container">
        <h2>Station Selection</h2>
        <div className="packing-main-content">
          <form onSubmit={handleStationSubmit}>
            <StyledDropdown
              options={dropdownOptions}
              value={selectedStation}
              onChange={handleStationChange}
              placeholder="Select Station"
            />
            <button type="submit" className="card-button">Set Station</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StationInput;
