import React, { useState } from 'react';
import Header from '../components/header';
import MedicineBagStore from '../components/Medicine_Bag/MedicineBagStore';
import NSMedicineBagStore from '../components/Medicine_Bag/NSMedicineBagStore';  // Import the NSMedicineBagStore component

const token = localStorage.getItem("token");

const MedicineBagStorePage = () => {
    const [isShowingNSMedicineBagStore, setIsShowingNSMedicineBagStore] = useState(false);  // State variable to determine which component to show

    return (
        <div className='main-content'>
            <Header token={token}/>
            <button className="button" onClick={() => setIsShowingNSMedicineBagStore(!isShowingNSMedicineBagStore)}>
                {isShowingNSMedicineBagStore ? "Switch to Barcodes" : "Switch to Manual"}
            </button>

            {isShowingNSMedicineBagStore ? <NSMedicineBagStore token={token}/> : <MedicineBagStore token={token}/>}
        </div>
    );
};

export default MedicineBagStorePage;
