import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';

const CarrierForm = ({ token, selectedCarrier }) => {
  const [carrierNumber, setCarrierNumber] = useState('');
  const [carrierType, setCarrierType] = useState('');
  const [carrierBarcode, setCarrierBarcode] = useState('');
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);

  useEffect(() => {
    if (selectedCarrier) {
      setCarrierNumber(selectedCarrier.carrier_number);
      setCarrierType(selectedCarrier.carrier_type);
      setCarrierBarcode(selectedCarrier.carrier_barcode);
      setSelectedCarrierId(selectedCarrier.carrier_barcode);
    }
  }, [selectedCarrier]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const carrierResponse = await fetch('/api/Carrier/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          carrier_number: carrierNumber,
          carrier_type: carrierType,
          carrier_barcode: carrierBarcode,
        }),
      });

      if (carrierResponse.ok) {
        const carrierData = await carrierResponse.json();
        console.log('Carrier created:', carrierData);

        // Create CarrierStatus
        const carrierStatusResponse = await fetch('/api/CarrierStatus/create/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            carrier_barcode: carrierBarcode,
            status: "Unpacked",
          }),
        });

        if (carrierStatusResponse.ok) {
          const carrierStatusData = await carrierStatusResponse.json();
          console.log('CarrierStatus created:', carrierStatusData);
          window.location.reload();
        } else {
          console.error('Error creating CarrierStatus:', carrierStatusResponse.statusText);
        }

      } else {
        console.error('Error creating carrier:', carrierResponse.statusText);
      }
    } catch (error) {
      console.error('Error creating carrier:', error);
    }
  };

  const handleRefresh = () => {
    setCarrierNumber('');
    setCarrierType('');
    setCarrierBarcode('');
    setSelectedCarrierId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete carrier with barcode ${carrierBarcode}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const barcodeChanged = selectedCarrierId !== carrierBarcode;

      const carrierResponse = await fetch(`/api/Carrier/update/${selectedCarrierId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          carrier_number: carrierNumber,
          carrier_type: carrierType,
          carrier_barcode: carrierBarcode,
        }),
      });

      if (carrierResponse.ok) {
        const carrierData = await carrierResponse.json();
        console.log('Carrier updated:', carrierData);

        if (barcodeChanged) {
          const carrierStatusResponse = await fetch(`/api/CarrierStatus/update/${selectedCarrierId}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              carrier_barcode: carrierBarcode,
            }),
          });

          if (carrierStatusResponse.ok) {
            const carrierStatusData = await carrierStatusResponse.json();
            console.log('CarrierStatus updated:', carrierStatusData);
          } else {
            console.error('Error updating CarrierStatus:', carrierStatusResponse.statusText);
          }
        }

        window.location.reload();
      } else {
        console.error('Error updating carrier:', carrierResponse.statusText);
      }
    } catch (error) {
      console.error('Error updating carrier:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const carrierResponse = await fetch(`/api/Carrier/delete/${selectedCarrierId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (carrierResponse.ok) {
        console.log('Carrier deleted');

        const carrierStatusResponse = await fetch(`/api/CarrierStatus/delete/${selectedCarrierId}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (carrierStatusResponse.ok) {
          console.log('CarrierStatus deleted');
          window.location.reload();
        } else {
          console.error('Error deleting CarrierStatus:', carrierStatusResponse.statusText);
        }

      } else {
        console.error('Error deleting carrier:', carrierResponse.statusText);
      }
    } catch (error) {
      console.error('Error deleting carrier:', error);
    }
  };



  const handleGenerateBarcode = async () => {
    try {
      const response = await fetch('/api/generate_unique_barcode/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setCarrierBarcode(data.unique_barcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  const questions = [
    {
        text: 'Carrier Number',
        value: carrierNumber,
        handleChange: (e) => setCarrierNumber(e.target.value),
        type: 'text',
    },
    {
        text: 'Carrier Type',
        value: carrierType,
        handleChange: (e) => setCarrierType(e.target.value),
        type: 'select',
        styled: true, // Use StyledDropdown
        options: [
            { value: "Create", label: "Create" },
        ],
    },
    {
        text: 'Carrier Barcode',
        value: carrierBarcode,
        handleChange: (e) => setCarrierBarcode(e.target.value),
        type: 'text',
        isLocked: true 
      },
  
        ...(!carrierBarcode ? [{
          text: 'Generate Barcode',
          type: 'generateBarcode'
        }] : [])
];

return (
    <div className="form-container">
        <DynamicForm
            title="Add New Carrier"
            questions={questions}
            handleSubmit={handleSubmit}
            handleRefresh={handleRefresh}
            handleUpdate={handleUpdate}
            handleDeleteConfirmation={handleDeleteConfirmation}
            handleGenerateBarcode={handleGenerateBarcode} 
            setDrugBarcode={setCarrierBarcode}  
            selectedId={selectedCarrierId}

        />
      </div>
    );
};

export default CarrierForm;

