import React, { useState } from 'react';
import Header from '../components/header';
import CarrierPack from '../components/CarrierTrack/CarrierPack';
import NSCarrierPack from '../components/CarrierTrack/NSCarrierPack'; // Assuming this is the correct import path
import CarrierTable from '../components/CarrierTrack/CarrierTable';

const token = localStorage.getItem("token");

const CarrierPackPage = () => {
    const [showNSCarrierPack, setShowNSCarrierPack] = useState(false);

    return (
        <div className='main-content'>
            <Header token={token}/>
            <button className='button' onClick={() => setShowNSCarrierPack(!showNSCarrierPack)}>
                {showNSCarrierPack ? 'Switch to CarrierPack' : 'Switch to NSCarrierPack'}
            </button>

            {showNSCarrierPack ? <NSCarrierPack token={token}/> : <CarrierPack token={token}/>}
            <CarrierTable token={token}/>
        </div>
    );
};

export default CarrierPackPage;
