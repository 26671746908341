import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const NSCarrierStore = ({ token }) => {
    const [carriers, setCarriers] = useState([]);
    const [filteredCarriers, setFilteredCarriers] = useState([]);
    const [selectedCarrierBarcode, setSelectedCarrierBarcode] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const fetchCarriers = async () => {
            try {
                const response = await axios.get('/api/packed_pouches_in_carrier/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    const uniqueCarriers = Array.from(new Set(response.data.map(item => ({
                        Carrier_Barcode: item.Carrier_Barcode,
                        Carrier_Number: item.Carrier_Number
                    }))));
                    setCarriers(uniqueCarriers);
                    setFilteredCarriers(uniqueCarriers);
                }
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        };

        fetchCarriers();
    }, [token]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredCarriers(carriers);
        } else {
            setFilteredCarriers(carriers.filter(carrier => carrier.Carrier_Number.toLowerCase().includes(searchQuery.toLowerCase())));
        }
    }, [searchQuery, carriers]);

    const handleSearchEnter = (e) => {
        if (e.key === 'Enter' && filteredCarriers.length > 0) {
            setSelectedCarrierBarcode(filteredCarriers[0].Carrier_Barcode);
        }
    };

    const storeAll = async () => {
        if (!selectedCarrierBarcode) {
            alert('Please select a carrier.');
            return;
        }

        const confirmStore = window.confirm(`Are you sure you want to store all pouches of ${selectedCarrierBarcode}?`);
        if (!confirmStore) {
            return;
        }

        const dataToUpdate = {
            Carrier_Barcode: selectedCarrierBarcode,  // Include this field
            Station_Stored: user.station_barcode,
            datetime_stored: new Date().toISOString(),
            User_Stored: user.userBarcode,
        };

        try {
            const response = await axios.put('/api/UpdateCarrierStore/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert(`Stored at Site ${user.station_barcode}`);
            } else {
                alert('Error storing pouches.');
            }
        } catch (error) {
            console.error('Error storing pouches:', error);
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Store Pouches in Carrier</h2>
                <div className="packing-main-content">
                    <form>
                        <div className="input-group">
                            <label>Search Carrier:</label>
                            <input 
                                type="text"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                onKeyDown={handleSearchEnter}
                            />
                        </div>
                        <div className="input-group">
                            <label>Select Carrier to Store:</label>
                            <select 
                                value={selectedCarrierBarcode} 
                                onChange={e => setSelectedCarrierBarcode(e.target.value)}
                            >
                                <option value="">Choose Carrier</option>
                                {filteredCarriers.map((carrier, index) => (
                                    <option key={index} value={carrier.Carrier_Barcode}>
                                        {carrier.Carrier_Number}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button onClick={storeAll} className="card-button">Store All in Selected Carrier</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NSCarrierStore;

