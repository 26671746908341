import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const CarrierStore = ({ token }) => {
    const [uniqueIds, setUniqueIds] = useState([]);
    const [carrierBarcode, setCarrierBarcode] = useState('');
    const user = useSelector((state) => state.user);

    console.log(user?.data);

    useEffect(() => {
        const fetchUniqueIds = async () => {
            try {
                const response = await axios.get('/api/packed_pouches_in_carrier/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                
                if (response.status === 200) {
                    const ids = response.data
                        .filter(item => item.Carrier_Barcode === carrierBarcode)
                        .map(item => item.Unique_ID);
                    setUniqueIds(ids);
                }
            } catch (error) {
                console.error('Error fetching unique ids:', error);
            }
        };

        if (carrierBarcode) {
            fetchUniqueIds();
        }
    }, [carrierBarcode, token]);

    const storeAll = async () => {
        const confirmStore = window.confirm(`Are you sure you want to store all pouches of ${carrierBarcode}?`);
        if (!confirmStore) {
            return;
        }
    
        const dataToUpdate = {
            Carrier_Barcode: carrierBarcode,  // Include this field
            Station_Stored: user.station_barcode,
            datetime_stored: new Date().toISOString(),  // Using 'datetime_stored' to match the model's field
            User_Stored: user.userBarcode,
        };
    
        try {
            const response = await axios.put('/api/UpdateCarrierStore/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert(`Stored at Site ${user.station_barcode}`);
            } else {
                alert('Error storing pouches.');
            }
        } catch (error) {
            console.error('Error storing pouches:', error);
        }
    };
    

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Store Pouches in Carrier</h2>
                <div className="packing-main-content">
                    <form>
                        <div className="input-group">
                            <label>Enter Carrier Barcode:</label>
                            <input 
                                type="text" 
                                value={carrierBarcode} 
                                onChange={e => setCarrierBarcode(e.target.value)} 
                            />
                        </div>
                        <button onClick={storeAll} className="card-button">Store All in Selected Carrier</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CarrierStore;
