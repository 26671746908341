import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DynamicForm from '../DynamicForm/DynamicForm';
import { useSelector } from 'react-redux';

const DrugAdministrationForm = ({ token, selectedRowData }) => {
    const [ePCRNumber, setEPCRNumber] = useState('');
    const [medicineName, setMedicineName] = useState('');
    const [administeredMg, setAdministeredMg] = useState('');
    const [wastedMg, setWastedMg] = useState('0');
    const [medicineOptions, setMedicineOptions] = useState([]);
    const user = useSelector((state) => state.user);
    const medicineBagBarcode = user.Medicine_bagBarcode;
    const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
    const userBarcode = useSelector((state) => state.user.userBarcode);
    const [pouchDetails, setPouchDetails] = useState(null);
    const [pouchContents, setPouchContents] = useState([]);
  // Format the date to match the datetime-local input requirements
  const formatDateForInput = (date) => {
    return date.toISOString().slice(0, 16);
  };

  const [dateTime, setDateTime] = useState(formatDateForInput(new Date()));



  useEffect(() => {
    const fetchMedicineDetails = async () => {
      try {
        const response = await axios.get(`api/medicine_bag_medicine_detail_data/${medicineBagBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setMedicineOptions(response.data.map(item => ({ 
            value: item.Medicine_Name, 
            label: item.Medicine_Name, 
            PouchHistory_Unique_ID: item.PouchHistory_Unique_ID 
          })));
                  }
      } catch (error) {
        console.error('Error fetching medicine details:', error);
      }
    };

    if (medicineBagBarcode) {
      fetchMedicineDetails();
    }
  }, [token, medicineBagBarcode]);

  useEffect(() => {
    if (selectedRowData) {
      setMedicineName(selectedRowData.Medicine_Name || '');
      // Add other fields here as needed
    }
  }, [selectedRowData]);

    // Fetch Pouch Details
    useEffect(() => {
      const fetchPouchDetails = async () => {
          try {
              const response = await axios.get(`/PouchBarcode/${pouchBarcode}/`, {
                  headers: { Authorization: `Bearer ${token}` },
              });
              setPouchDetails(response.data);
          } catch (error) {
              console.error('Error fetching pouch details:', error);
          }
      };

      if (pouchBarcode) {
          fetchPouchDetails();
      }
  }, [token, pouchBarcode]);

  // Fetch Pouch Contents
  useEffect(() => {
      if (pouchDetails) {
          const fetchPouchContents = async () => {
              try {
                  const response = await axios.get(`/PouchContents/${pouchDetails.Pouch_Number}/`, {
                      headers: { Authorization: `Bearer ${token}` },
                  });
                  setPouchContents(response.data);
              } catch (error) {
                  console.error('Error fetching pouch contents:', error);
              }
          };

          fetchPouchContents();
      }
  }, [token, pouchDetails]);



  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Fetch MedicineInPouch data by pouch_barcode and medicine_barcode using the new filter view
      const medicineInPouchResponse = await axios.get(`/api/MedicineInPouch/filter/?pouch_barcode=${pouchBarcode}&medicine_barcode=${medicineName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const medicineInPouchData = medicineInPouchResponse.data;
  
      // Check if there are any records found
      if (!medicineInPouchData || medicineInPouchData.length === 0) {
        alert("No medicine found in the pouch with the specified barcode.");
        return;
      }
  
      // Handle the case for each record found
      for (const pouchData of medicineInPouchData) {
        const newAmount = pouchData.amount - parseInt(administeredMg);
  
        // If the new amount is zero, delete the record
        if (newAmount === 0) {
          await axios.delete(`/api/MedicineInPouch/delete/${pouchData.unique_id}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else if (newAmount > 0) {
          // Update the record with the new amount
          await axios.put(`/api/MedicineInPouch/update/${pouchData.unique_id}/`, {
            drug_barcode: medicineName,
            pouch_barcode: pouchBarcode,
            amount: newAmount,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      }


          // Add logic to determine status and update pouch status
    const updatePouchStatusBasedOnAmount = async () => {
      try {
          const response = await axios.get(`/api/pouch_remaining_data/${pouchBarcode}/${pouchDetails.Pouch_Type}`, {
              headers: { Authorization: `Bearer ${token}` },
          });

          let status = 'used';
          for (const item of response.data) {
              if (item.Remaining_Amount < item.Red_Tag_Amount) {
                  status = 'Used needs returning';
                  break;
              }
          }

          await axios.put(`/PouchStatus/update/${pouchBarcode}`, { status }, {
              headers: { Authorization: `Bearer ${token}` },
          });
      } catch (error) {
          console.error('Error updating pouch status:', error);
      }
  };
  updatePouchStatusBasedOnAmount()
  
      // Create a new drug administration record
      const drugAdminResponse = await axios.post('/api/DrugAdmin/create/', {
        ePCR_Number: ePCRNumber,
        Medicine_Name: medicineName,
        Administered_mg: administeredMg,
        Wasted_mg: wastedMg,
        Date_and_Time: dateTime,
        MedicineBag_Barcode: medicineBagBarcode,
        Pouch_Barcode: pouchBarcode,
        User_Barcode: userBarcode,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (drugAdminResponse.status !== 201) {
        console.error('Error creating drug administration record:', drugAdminResponse.statusText);
      }
    } catch (error) {
      console.error('Error in handling submission:', error);
    }


  };
  
  

  const questions = [
    {
      text: 'ePCR Number',
      value: ePCRNumber,
      handleChange: (e) => setEPCRNumber(e.target.value),
      type: 'text',
    },
    {
      text: 'Medicine Given',
      value: medicineName,
      handleChange: (e) => setMedicineName(e.target.value),
      type: 'select',
      styled: true,
      options: medicineOptions,
    },
    {
      text: 'Administered mg',
      value: administeredMg,
      handleChange: (e) => setAdministeredMg(e.target.value),
      type: 'number',
    },
    {
      text: 'Wasted mg',
      value: wastedMg,
      handleChange: (e) => setWastedMg(e.target.value),
      type: 'number',
    },
    {
      text: 'Date and Time',
      value: dateTime,
      handleChange: (e) => setDateTime(e.target.value),
      type: 'datetime-local',
    },
  ];

  return (
    <div className="form-container">
      <DynamicForm
        title="Add New Drug Administration Record"
        questions={questions}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default DrugAdministrationForm;
