// src/components/PouchNumberInput.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setPouchData } from '../../Redux/slices/userSlice';
import Textinput from '../Styling components/Textinput';

const PouchNumberInput = () => {
  const [inputBarcode, setInputBarcode] = useState('');
  const [selectedPouch, setSelectedPouch] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPouch = async (pouchBarcode) => {
      if (!pouchBarcode) return;

      try {
        const response = await axios.get(`api/Pouch/${pouchBarcode}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          setSelectedPouch(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouch:', error);
      }
    };

    fetchPouch(inputBarcode);
  }, [inputBarcode]);

  const handleInputChange = (e) => {
    setInputBarcode(e.target.value);
  };

  const handleAddPouchNumber = () => {
    if (selectedPouch) {
      dispatch(setPouchData({
        pouchNumber: selectedPouch.pouch_number,
        pouchType: selectedPouch.pouch_type,
        pouchBarcode: selectedPouch.pouch_barcode
      }));
    }
  };
  

  const questions = [
    {
      type: 'text',
      value: inputBarcode,
      handleChange: handleInputChange,
      text: 'Pouch Barcode'
    }
  ];

  return (
    <div className="medicine-upload-card">
      <h2 className="medicine-upload-header">Pouch Selection</h2>
      <div className="medicine-upload-body">
        <Textinput questions={questions} />

        <div className="medicine-upload-item">
          <span className="medicine-upload-label">Pouch Number: </span>
          <span className="medicine-upload-value">
            {selectedPouch ? selectedPouch.pouch_number : "N/A"}
          </span>
        </div>

        <div className="medicine-upload-item">
          <button className="medicine-upload-button" type="button" onClick={handleAddPouchNumber}>
            Add Pouch Number
          </button>
        </div>
      </div>
    </div>
  );
};

export default PouchNumberInput;


