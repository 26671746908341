import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setPouchData } from '../../Redux/slices/userSlice';
import StyledDropdown from '../Styling components/StyledDropdown';
import Textinput from '../Styling components/Textinput';

const PouchNumberInput = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPouch, setSelectedPouch] = useState(null);
  const [pouches, setPouches] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPouches = async () => {
      try {
        const response = await axios.get('api/Pouch/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
    
   
        if (response.status === 200) {
          setPouches(response.data);
        }
      } catch (error) {
        console.error('Error fetching pouches:', error);
      }
    };
  
    fetchPouches();
  }, []);
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddPouchNumber = () => {
    if (selectedPouch) {
      dispatch(setPouchData({
        pouchNumber: selectedPouch.pouch_number,
        pouchType: selectedPouch.pouch_type,
        pouchBarcode: selectedPouch.pouch_barcode
      }));
      window.alert(`Pouch ${selectedPouch.pouch_number} selected`);  // Added this line
    }
  };

  const handleSelectChange = (e) => {
    const pouchNumber = e.target.value;
    const selected = pouches.find(pouch => pouch.pouch_number === pouchNumber);
    setSelectedPouch(selected);
  };

  const filteredPouches = pouches.filter(pouch =>
    pouch.pouch_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="medicine-upload-card">
      <h2 className="medicine-upload-header">Pouch Selection</h2>
      <div className="medicine-upload-body">
        <Textinput
          questions={[
            {
              type: 'text',
              text: 'Search pouch number...',
              value: searchTerm,
              handleChange: (e) => setSearchTerm(e.target.value),
            }
          ]}
        />
        <StyledDropdown
          options={filteredPouches.map(pouch => ({ value: pouch.pouch_number, label: pouch.pouch_number }))}
          value={selectedPouch ? selectedPouch.pouch_number : ''}
          onChange={handleSelectChange}
          placeholder="Select pouch"
        />
        <div className="medicine-upload-item">
          <span className="medicine-upload-label">Pouch Number: </span>
          <span className="medicine-upload-value">
            {selectedPouch ? selectedPouch.pouch_number : "N/A"}
          </span>
        </div>
        <div className="medicine-upload-item">
          <button className="medicine-upload-button" type="button" onClick={handleAddPouchNumber}>
            Add Pouch Number
          </button>
        </div>
      </div>
    </div>
  );
};

export default PouchNumberInput;

