import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';

const MedicinePouchTypeForm = ({ token, selectedMedicineType  }) => {
  const [pouchTypeName, setPouchTypeName] = useState('');
  const [medicineName, setMedicineName] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [redTagAmount, setRedTagAmount] = useState('');
  const [selectedMedicineTypeId, setSelectedMedicineTypeId] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [pouchTypeNames, setPouchTypeNames] = useState([]);
  const [consumables, setConsumables] = useState([]); 

  useEffect(() => {
    if (selectedMedicineType) {
      setPouchTypeName(selectedMedicineType['Pouch Type Name']);
      setMedicineName(selectedMedicineType['Medicine Name']);
      setMaxAmount(selectedMedicineType['Max Amount']);
      setMinAmount(selectedMedicineType['Min Amount']);
      setRedTagAmount(selectedMedicineType['Red Tag Amount']);
      setSelectedMedicineTypeId(selectedMedicineType.Unique_id);
    }
}, [selectedMedicineType]);



  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        let response = await fetch('/api/Medicine/', requestOptions);
        let data = await response.json();
        setMedicines(data);
      } catch (error) {
        console.error('Error fetching medicines:', error);
      }
    };

    const fetchPouchTypeNames = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        let response = await fetch('/api/PouchTypeName/', requestOptions);
        let data = await response.json();
        setPouchTypeNames(data);
      } catch (error) {
        console.error('Error fetching pouch type names:', error);
      }
    };

  const fetchConsumables = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      let response = await fetch('/api/ConsumableBatch/', requestOptions);
      let data = await response.json();
      setConsumables(data);
    } catch (error) {
      console.error('Error fetching consumables:', error);
    }
  };

  fetchMedicines();
  fetchPouchTypeNames();
  fetchConsumables();
}, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/PouchType/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pouch_type_name: pouchTypeName,
          medicine_name: medicineName,
          max_amount: maxAmount,
          min_amount: minAmount,
          red_tag_amount: redTagAmount,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Pouch Type created:', data);
        window.location.reload();
      } else {
        console.error('Error creating pouch type:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating pouch type:', error);
    }
  };
  
  const handleUpdate = async () => {
    try {
      const response = await fetch(`/api/PouchType/update/${selectedMedicineTypeId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pouch_type_name: pouchTypeName,
          medicine_name: medicineName,
          max_amount: maxAmount,
          min_amount: minAmount,
          red_tag_amount: redTagAmount,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Pouch Type updated:', data);
        window.location.reload();
      } else {
        console.error('Error updating pouch type:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating pouch type:', error);
    }
  };
  
  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/PouchType/delete/${selectedMedicineTypeId}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        console.log('Pouch Type deleted');
        window.location.reload();
      } else {
        console.error('Error deleting pouch type:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting pouch type:', error);
    }
  };
 
  const handleRefresh = () => {
    setPouchTypeName('');
    setMedicineName('');
    setMaxAmount('');
    setMinAmount('');
    setRedTagAmount('');
    setSelectedMedicineTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${medicineName}?`)) {
      handleDelete();
    }
  }  

    // Combine medicines and consumables for the dropdown
  const combinedOptions = [
      ...medicines.map(medicine => ({
        value: medicine.medicine_name, 
        label: medicine.medicine_name,
        type: 'medicine'
      })),
      ...consumables.map(consumable => ({
        value: consumable.consumable_name, 
        label: consumable.consumable_name,
        type: 'consumable'
      })),
    ];


  const questions = [
    {
      text: 'Medicine/Consumable Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setMedicineName(e.target.value),
      value: medicineName,
      options: combinedOptions,
    },
    {
      text: 'Pouch Type Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setPouchTypeName(e.target.value),
      value: pouchTypeName,
      options: pouchTypeNames.map((pouchTypeName) => ({ value: pouchTypeName.pouch_type_name, label: pouchTypeName.pouch_type_name })),
    },
    {
      text: 'Max Amount',
      type: 'number',
      handleChange: (e) => setMaxAmount(e.target.value),
      value: maxAmount,
    },
    {
      text: 'Min Amount',
      type: 'number',
      handleChange: (e) => setMinAmount(e.target.value),
      value: minAmount,
    },
    {
      text: 'Red Tag Amount',
      type: 'number',
      handleChange: (e) => setRedTagAmount(e.target.value),
      value: redTagAmount,
    }
  ];

  return (
    <DynamicForm
      title="Add New Pouch Type"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedMedicineTypeId}
    />
  );
};

export default MedicinePouchTypeForm;

