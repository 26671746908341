// QAHeader.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../../static/CSS/HeaderPacking.css'; // Make sure you create and import this CSS file

const QAHeader = ({ togglePouchForm, showBarcodeInput, setShowBarcodeInput }) => {
  const [showSwitchButton, setShowSwitchButton] = useState(false);
  const user = useSelector((state) => state.user);
  const { name, station, pouchNumber } = user;

  const handlePouchButtonClick = () => {
    // Toggle the form as well as the switch button's visibility
    togglePouchForm();
    setShowSwitchButton(!showSwitchButton);
  };

  return (
    <div className="qa-header">
      <div className="header-left">
        <h2 className='qa-title'>Quality Assurance</h2>
        <p className="user-greeting">Hi {name}</p>
        <button className="pouch-button" onClick={handlePouchButtonClick}>
          Pouch
        </button>
      </div>
      <div className="header-right">
        <div className="qa-details">
          <div className="qa-detail">
            <span className="detail-label">Pouch Number:</span>
            <span className="detail-value">{pouchNumber}</span>
          </div>
          <div className="qa-detail">
            <span className="detail-label">Station Name:</span>
            <span className="detail-value">{station}</span>
          </div>
          {showSwitchButton && (
            <button 
              className="switch-button" 
              onClick={() => setShowBarcodeInput(!showBarcodeInput)}>
              {showBarcodeInput ? 'Switch to Manual' : 'Switch to Barcode'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QAHeader;