import React, { useState } from 'react';
import axios from 'axios';

const ForgotPasswordModal = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post('/api/forgot_password/', {
        email,
      });
      
      if (response.status === 200) {
        setMessage('Temporary password has been sent to your email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to send email.');
    }
  };

  return (
    <div>
      <button onClick={() => handleForgotPassword()}>Forgot Password</button>
      {message && <div>{message}</div>}
    </div>
  );
};

export default ForgotPasswordModal;
