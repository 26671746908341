// PackingHeader.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../static/CSS/HeaderPacking.css';

const PackingHeader = ({ token, showBarcodeInput, setShowBarcodeInput }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { name, station, pouchBarcode, pouchNumber } = user;
  const [editStation, setEditStation] = useState(false);

  const handlePackButtonClick = () => {
    navigate('/pack', {
      state: {
        user: user,
        stationName: station,
        pouchBarcode: pouchBarcode,
      },
    });
  };

  return (
    <div className="packing-header">
      <div className="header-left">
        <h2 className='packing-title'>Packing</h2>
        <p className="user-greeting">Hi {name}</p>
        <button className="switch-button" onClick={() => setShowBarcodeInput(!showBarcodeInput)}>
          {showBarcodeInput ? 'Switch to Manual' : 'Switch to Barcode'}
        </button>
      </div>
      <div className="header-right">
        <div className="packing-detail">
          <span className="detail-label">Pouch Number:</span>
          <span className="detail-value">{pouchNumber}</span>
        </div>
        <div className="packing-detail">
          <span className="detail-label">Station Name:</span>
          <span className="detail-value">{station}</span>
        </div>
        <div className="packing-actions">
          <p className="packing-instruction">If all correct, please proceed to pack.</p>
          <button className="pack-button" onClick={handlePackButtonClick}>Pack</button>
        </div>
      </div>
    </div>
  );
};

export default PackingHeader;
