// FormattedForm.js
import React from 'react';

const Textinput = ({ questions, isLocked }) => {
  return (
    <>
      {questions.map((question, index) => (
        <div key={index} className="account-card-item">
          <div className="Form-input-field">
            <input 
              type={question.type} 
              value={question.value || ''} 
              onChange={question.handleChange} 
              required 
              readOnly={isLocked}
            />
            <label>
              {question.text.split('').map((char, i) => 
                <span key={i} style={{transitionDelay: `${i * 50}ms`, margin: char === ' ' ? '0 2px' : '0'}}>{char}</span>
              )}
            </label>
          </div>
        </div>
      ))}
    </>
  );
};

export default Textinput;
