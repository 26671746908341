import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import LocationForm from '../components/Station Carrier Inputs/StationInput';
import MedicineBag_inputs from '../components/Station Medicine_Bag Inputs/Medicine_bagInput';
import { useSelector } from 'react-redux';

const token = localStorage.getItem("token");

const MedicineBagLoad = () => {
  const { username, station, pouchNumber, Medicine_bag } = useSelector((state) => state.user); // Changed carrier to medicineBag

  useEffect(() => {
  }, [username, station, pouchNumber]);

  return (
    <div className='main-content'>
      <Header />
      <LocationForm token={token} />

      <MedicineBag_inputs token={token} /> 
      <div className="button-container"> 
      <div className="selected-medicineBag-info">
          {Medicine_bag ? `MedicineBag: ${Medicine_bag}` : 'No medicine bag selected'} 
      </div>
      <Link to="/MedicineBagPackPage"><button className='button'>Pack MedicineBag</button></Link>
      <Link to="/MedicineBagStorePage"><button className='button'>Store MedicineBag</button></Link>
      <Link to="/MedicineBagUnpackPage"><button className='button'>Unpack MedicineBag</button></Link>
      </div>
    </div>
  );
};

export default MedicineBagLoad;
