import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';

const UserPositionForm = ({ token, selectedPosition }) => {
  const [positionName, setPositionName] = useState('');
  const [selectedPositionId, setSelectedPositionId] = useState(null);

  useEffect(() => {
    if (selectedPosition) {
      setPositionName(selectedPosition.name);
      setSelectedPositionId(selectedPosition.id);
    }
  }, [selectedPosition]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/UserPosition/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: positionName,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Position created:', data);
        window.location.reload();
      } else {
        console.error('Error creating position:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating position:', error);
    }
  };

  const handleRefresh = () => {
    setPositionName('');
    setSelectedPositionId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${positionName}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`/api/UserPosition/update/${selectedPositionId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: positionName,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Position updated:', data);
        window.location.reload();
      } else {
        console.error('Error updating position:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating position:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/UserPosition/delete/${selectedPositionId}/`, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log('Position deleted');
        window.location.reload();
      } else {
        console.error('Error deleting position:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting position:', error);
    }
  };

  const questions = [
    {
      type: 'text',
      text: 'Position Name',
      value: positionName,
      handleChange: (e) => setPositionName(e.target.value),
    },
  ];

  return (
    <DynamicForm
      title="Add New Position"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedPositionId}
    />
  );
};

export default UserPositionForm;
