import React, { useState } from 'react';
import Header from '../components/header';
import CarrierStore from '../components/CarrierTrack/CarrierStore';
import NSCarrierStore from '../components/CarrierTrack/NSCarrierStore';  // Import the NSCarrierStore component

const token = localStorage.getItem("token");

const CarrierStorePage = () => {
    const [isShowingNSCarrierStore, setIsShowingNSCarrierStore] = useState(false);  // State variable to determine which component to show

    return (
        <div className='main-content'>
            <Header token={token}/>
            <button className="button" onClick={() => setIsShowingNSCarrierStore(!isShowingNSCarrierStore)}>
                {isShowingNSCarrierStore ? "Switch to Barcodes" : "Switch to Manual"}
            </button>

            {isShowingNSCarrierStore ? <NSCarrierStore token={token}/> : <CarrierStore token={token}/>}
        </div>
    );
};

export default CarrierStorePage;
