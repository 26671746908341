import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserDetail } from '../../Redux/slices/userSlice'; 

import LocationForm from '../Station Carrier Inputs/StationInput';
import PouchNumberInput from '../Packing/PouchNumberInput';
import NSPouchNumberInput from '../Packing/NSPouchNumberInput';
import MedicineBag_inputs from '../Station Medicine_Bag Inputs/Medicine_bagInput';
import CarrierPack from '../CarrierTrack/CarrierPack';
import NSCarrierPack from '../CarrierTrack/NSCarrierPack';
import '../../static/CSS/UserDetails.css'; 

const UserDetails = ({ token }) => {
    const [showComponent, setShowComponent] = useState(null);
    const [useNSPouch, setUseNSPouch] = useState(false);
    const [useNSCarrier, setUseNSCarrier] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
  
    const handleRefresh = (details) => {
      dispatch(clearUserDetail({ details }));
    };

  return (
    <div className="user-details">
        <div className="user-title">Detail Check</div> {/* Add this line for the title */}

      <div className="user-detail">
        <p>User: {user.name}</p>
        <a href="/login" className="button">Logout</a>
        <button className='button' onClick={() => handleRefresh('username')}>Refresh</button>
      </div>

      <div className="user-detail">
        <p>Station: {user.station}</p>
        <button className='button'  onClick={() => setShowComponent('station')}>Update</button>
        <button className='button' onClick={() => handleRefresh(['station', 'station_barcode'])}>Refresh</button>
      </div>
      {showComponent === 'station' && <LocationForm token={token} />}



      <div className="user-detail">
        <p>Pouch Number: {user.pouchNumber}</p>
        <button className='button' onClick={() => setShowComponent('pouchNumber')}>Update</button>
        <button className='button' onClick={() => handleRefresh(['pouchNumber', 'pouchType', 'pouchBarcode'])}>Refresh</button>
      </div>
      {showComponent === 'pouchNumber' && (
        <>
          {useNSPouch ? <NSPouchNumberInput token={token} /> : <PouchNumberInput token={token} />}
          <button className='button' onClick={() => setUseNSPouch(!useNSPouch)}>
            {useNSPouch ? 'Use Standard Pouch' : 'Use NS Pouch'}
          </button>
        </>
      )}

      <div className="user-detail">
        <p>Carrier: {user.carrier}</p>
        <button className='button' onClick={() => setShowComponent('carrier')}>Update</button>
        <button className='button' onClick={() => handleRefresh(['carrier', 'carrierBarcode'])}>Refresh</button>
      </div>
      {showComponent === 'carrier' && (
        <>
          {useNSCarrier ? <NSCarrierPack token={token} /> : <CarrierPack token={token} />}
          <button className='button' onClick={() => setUseNSCarrier(!useNSCarrier)}>
            {useNSCarrier ? 'Use Standard Carrier' : 'Use NS Carrier'}
          </button>
        </>
      )}

      <div className="user-detail">
        <p>Medicine Bag: {user.Medicine_bag}</p>
        <button className='button' onClick={() => setShowComponent('medicineBag')}>Update</button>
        <button className='button' onClick={() => handleRefresh(['Medicine_bag', 'Medicine_bagBarcode'])}>Refresh</button>
      </div>
      {showComponent === 'medicineBag' && <MedicineBag_inputs token={token} />}
    </div>
  );
};

export default UserDetails;
