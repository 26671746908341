// src/pages/ContactPage.js

import React from 'react';
import LandingPageHeader from './LandingPageHeader'; // Import the header
import '../../static/CSS/LandingPageHeader.css';

const ContactPage = () => {
  return (
    <div className="contact-page">
      <LandingPageHeader />  {/* Include the header */}

      <h1>Contact Us</h1>
      <p>Here you can find all the ways to get in touch with us.</p>

      {/* Add more contact details as needed */}
    </div>
  );
};

export default ContactPage;
