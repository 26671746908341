import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom'; 
import Textinput from '../Styling components/Textinput';
import StyledDropdown from '../Styling components/StyledDropdown';

const NSMedicineRemove = ({ token, onUpdate }) => {
  const [medicineBarcode, setMedicineBarcode] = useState('');
  const [medicineName, setMedicineName] = useState('');
  const [amount, setAmount] = useState('');
  const [pouchAmount, setPouchAmount] = useState(null);
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  const pouchNumber = useSelector((state) => state.user.pouchNumber);
  const name = useSelector((state) => state.user.name);
  const [selectedUniqueId, setSelectedUniqueId] = useState(null);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);
  const navigate = useNavigate();
  const [pouchContents, setPouchContents] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [selectedBatchNumber, setSelectedBatchNumber] = useState('');
  const [uniqueId, setUniqueId] = useState(''); 
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState("");
  const [isUnexpectedMedicine, setIsUnexpectedMedicine] = useState(false);
  const [showPopup, setShowPopup] = useState(false); 
  const [uniqueIds, setUniqueIds] = useState([]); // add this line
  const [selectedBatchUniqueIds, setSelectedBatchUniqueIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const amountRef = useRef(amount);  // Create a ref for amount
  const pouchAmountRef = useRef(pouchAmount); 

  useEffect(() => {
    amountRef.current = amount;  // Update the ref whenever amount changes
  }, [amount]);

  useEffect(() => {
  }, [selectedBatchUniqueIds]);

  useEffect(() => {
    pouchAmountRef.current = pouchAmount;  // Update the ref whenever pouchAmount changes
  }, [pouchAmount]);


  const handleSelection = (uniqueId) => {
    setSelectedUniqueId(uniqueId);
  };

  const handleNoMoreMedicines = () => {
    const confirmation = window.confirm("Are you sure there is nothing left in the pouch?");
    if (confirmation) {
      navigate('/Unpacking'); 
    }
  };
  
  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const response = await axios.get('/api/MedicineBatch/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setMedicines(response.data);
        }
      } catch (error) {
        console.error('Error fetching medicines:', error);
      }
    };
  
    fetchMedicines();
  }, [token]);
  
  const handleSelectChange = async (e) => {
    setIsLoading(true);
  
    const selectedMedicineName = e.target.value;
    setMedicineName(selectedMedicineName);
  
    try {
      // Fetch the items of the selected medicine
      const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        // Filter the items for the selected medicine
        const medicineItems = response.data.filter(item => item['Medicine_Name'] === selectedMedicineName);

        // Group the items by batch number and count the amount for each batch
        const groupedData = medicineItems.reduce((acc, item) => {
          const key = item.Batch_Number;
          if (!acc[key]) {
            acc[key] = {
              ...item,
              Amount: 0,
              Unique_IDs: [],  // Add a field to store the unique IDs
            };
          }
          acc[key].Amount += 1;
          acc[key].Unique_IDs = [...acc[key].Unique_IDs, item.unique_id];  // Create a new array for Unique_IDs
          return acc;
        }, {});
        
  
        // Log the grouped data to check the Unique_IDs field
  
        // Convert the grouped data object to an array
        const filteredData = Object.values(groupedData);
    
        setFilteredTableData(filteredData);
      }
    } catch (error) {
      console.error('Error in handleSelectChange:', error);
    }
    setIsLoading(false);
  };
  
  
  
  
  useEffect(() => {
    const fetchPouchAmountAndContents = async () => {
      try {
        const pouchAmountResponse = await axios.get(`/api/pouch_medicine_data/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        // Filter the records for the current medicine
        const medicineItemsAmount = pouchAmountResponse.data.filter(item => item['Medicine_Name'] === medicineName);
    
        // Calculate the total amount of the current medicine in the pouch
        const totalAmount = medicineItemsAmount.reduce((sum, item) => sum + item.total_amount, 0);
  
        setPouchAmount(totalAmount);
    
        // Fetch the unique IDs of the medicine items in the pouch
        const pouchContentsResponse = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
    
        // Filter the records for the current medicine
        const medicineItems = pouchContentsResponse.data.filter(item => item['Medicine_Name'] === medicineName);
    
    
        // Extract the unique IDs
        const ids = medicineItems.map(item => item.unique_id);
    
    
        setUniqueIds(ids);
    
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };
  
    if (pouchBarcode && medicineName && medicineName !== '') {
      fetchPouchAmountAndContents();
    } else if (medicineName !== '') {
      setPouchAmount(0);
      setUniqueIds([]);
    }
  }, [pouchBarcode, medicineName, token]);
  
  

  const updateTableData = async (medicineBarcode) => {
    try {
      const response = await axios.get(
        `/api/pouch_contents_from_pouch_history/${pouchBarcode}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const data = response.data.filter(
        (item) => item.Drug_Barcode === medicineBarcode
      );
  
      // Group the data by medicine name and batch number
      const groupedData = data.reduce((acc, item) => {
        const key = `${item.Medicine_Name}-${item.Batch_Number}`;
        if (!acc[key]) {
          acc[key] = {
            ...item,
            Amount: 0
          };
        }
        acc[key].Amount += 1;
        return acc;
      }, {});
  
      // Convert the grouped data object to an array
      const filteredData = Object.values(groupedData);
  
      setFilteredTableData(filteredData);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  }
  

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};

const filteredMedicines = medicines.filter(medicine => 
  medicine.medicine_name && medicine.medicine_name.toLowerCase().includes(searchTerm.toLowerCase())
);

  

useEffect(() => {
  if (isLoading) {
    return;
  }

  const fetchPouchContents = async () => {
      try {
        const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
    
        const medicineItems = response.data.filter(item => item.Medicine_Name === medicineName);

        if (medicineItems.length > 0) {
          setPouchAmount(medicineItems.length);
          setIsUnexpectedMedicine(false); // set to false if medicine is expected
        } else if (medicineName) {
          setIsUnexpectedMedicine(true); // set to true if medicine is not expected but name is entered
          setPouchAmount(0);
        }
    
        // Update the prompt logic
        if (isUnexpectedMedicine) {
          const reason = window.prompt("Please provide a reason for this medicine:");
          if (reason) {
            try {
              // Update each existing row
              for (let i = 0; i < medicineItems.length; i++) {
                await axios.put(`/api/update_pouch_history/${medicineItems[i].Unique_ID}/`, {
                  Datetime_QA: new Date().toISOString(),
                  User_QA: userBarcode,
                  comment: reason
                }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
              }
    
              alert("Medicine updated and returned successfully");
            } catch (error) {
              console.error("Error updating and returning medicine:", error);
              alert("Error updating and returning medicine");
            }
          }   
        }
    
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };
    
  

    if (pouchBarcode && medicineName !== '') {
      fetchPouchContents();
    } else {
      setPouchContents([]);
      setPouchAmount(0);
      setIsUnexpectedMedicine(false);
    }
  }, [pouchBarcode, token, medicineName, isLoading, isUnexpectedMedicine]);

    
    
    
    
      useEffect(() => {
        const fetchFilteredData = async () => {
          try {
            const response = await axios.get(
              `/api/pouch_medicine_data/${pouchBarcode}/`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
      
            const filteredData = response.data.filter(
              (item) => item.Drug_Barcode === medicineBarcode
            );
      
            setFilteredTableData(filteredData);
          } catch (error) {
            console.error("Error fetching filtered data:", error);
          }
        };
      
        if (pouchBarcode && medicineBarcode) {
          fetchFilteredData();
        } else {
          setFilteredTableData([]);
        }
      }, [pouchBarcode, medicineBarcode, token]);
      
      const handleButtonClick = (batchNumber) => {
        // Find the row for the selected batch number
        const selectedBatch = filteredTableData.find(item => item.Batch_Number === batchNumber);
      
        if (selectedBatch) {
          // Store the unique IDs of the selected batch
          setSelectedBatchUniqueIds(selectedBatch.Unique_IDs);
        }
      
        setShowPopup(true);  // Show the popup when the "Select" button is clicked
      };
      
      
    
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Pouch Barcode: ", pouchBarcode);
        console.log("Medicine Barcode: ", medicineBarcode);
        if (!pouchBarcode || !medicineBarcode) {
          alert('Pouch barcode or medicine barcode is missing.');
          return;
        }

        if (parseInt(amount) <= 0) {
          alert("The inputted amount must be greater than zero.");
          return;
        }
      
        if (parseInt(amount) > pouchAmount) {
          alert("The inputted amount is more than the amount in the pouch. Operation cancelled.");
          return;
        }
      
        try {
          // Fetch MedicineInPouch data by pouch_barcode and medicine_barcode using the new filter view
          const medicineInPouchResponse = await axios.get(`/api/MedicineInPouch/filter/?pouch_barcode=${pouchBarcode}&medicine_barcode=${medicineBarcode}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        
          const medicineInPouchData = medicineInPouchResponse.data;
        
          if (!medicineInPouchData || medicineInPouchData.length === 0) {
            alert("No medicine found in the pouch with the specified barcode.");
            return;
          }
        
          // Assuming there's only one entry for a specific medicineBarcode and pouchBarcode
          const pouchData = medicineInPouchData[0];
          const newAmount = pouchData.amount - parseInt(amount);
        
          // If the new amount is zero, delete the record
          if (newAmount === 0) {
            await axios.delete(`/api/MedicineInPouch/delete/${pouchData.unique_id}/`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          } else if (newAmount > 0) {
            // Update the record with the new amount
            await axios.put(`/api/MedicineInPouch/update/${pouchData.unique_id}/`, {
              drug_barcode: medicineBarcode,
              pouch_barcode: pouchBarcode,
              amount: newAmount,
            }, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          } else {
            alert("The inputted amount is more than the amount in the pouch. Operation cancelled.");
            return;
          }
      
          // Update the pouch history and stock holding history
          let remainingAmount = parseInt(amount);

          // Update the record with the new amount
              await axios.put(`/api/MedicineInPouch/update/${pouchData.unique_id}/`, {
                drug_barcode: medicineBarcode,
                pouch_barcode: pouchBarcode,
                amount: newAmount,
              }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
          // Update each record
          for (const uniqueId of selectedBatchUniqueIds) {
            if (remainingAmount <= 0) {
              break;
            }
      
            // Update the record
            await axios.put(`/api/update_pouch_history/${uniqueId}/`, {
              user_unpacked: userBarcode,
              datetime_unpacked: new Date().toISOString(),
              status: 'Unpacked',
            }, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
      
            // Subtract one unit from the remaining amount
            remainingAmount -= 1;
          }
      
          if (remainingAmount > 0) {
            console.warn(`${remainingAmount} units of the medicine could not be removed because the pouch didn't contain enough units.`);
          }
      
          alert("Medicine removed successfully");
        } catch (error) {
          console.error("Error removing medicine:", error);
          alert("Error removing medicine");
        }
      
        const newTableData = filteredTableData.filter(item => !selectedBatchUniqueIds.includes(item.Unique_ID));
        setFilteredTableData(newTableData);
      
        setUpdateKey((prevKey) => prevKey + 1);
      
        if (typeof onUpdate === 'function') {
          onUpdate();
        }
      
        setMedicineBarcode('');
        setAmount('');
        setSelectedBatchUniqueIds([]);
        setShowPopup(false);
      };
      
    
      const handleUnexpectedMedicine = async () => {
        try {
            await axios.post('/api/StockHoldingHistory/create/', {
                location: stationBarcode,
                user_barcode: userBarcode,
                drug_barcode: medicineBarcode,
                amount: 1,
                datetime_transaction: new Date().toISOString(),
                comment: `UnPacked by ${name} Unexpected`,
                datetime_qa: new Date().toISOString(),
                user_qa: 'Return',
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            alert("Medicine marked as Present but not expected.");
    
                    // Refresh the form by resetting the state.
                    setMedicineBarcode('');
                    setAmount('');
                    setSelectedUniqueId(null);
                    setMedicineName('');
                    setPouchAmount(null);
                    setIsUnexpectedMedicine(false);
    
                    
        } catch (error) {
            console.error("Error marking medicine as Present but not expected:", error);
            alert("Error marking medicine as Present but not expected");
        }
    };
    
      const questions = [
        {
          text: 'Amount to Remove',
          type: 'number',
          value: amount,
          handleChange: (e) => setAmount(e.target.value)
        }
      ];
      
      const medicineOptions = filteredMedicines.map(medicine => ({
        label: medicine.medicine_name
      }));
    
    
      useEffect(() => {
        const amountInPouch = filteredTableData.reduce((total, item) => total + item.Amount, 0);
        setPouchAmount(amountInPouch);
      }, [filteredTableData]);
    
      return (
        <div className="medicine-return-container">
          <div className="form-container">
            <div className="medicine-return-card">
              <div className="medicine-return-card-header">
                <h2>Medicine to Remove</h2>
              </div>
              <div className="medicine-return-card-body">
                <form onSubmit={handleSubmit} className="medicine-return-form">
                  
                  {/* Using StyledDropdown component here */}
                  <div className="account-card-item">
                    <StyledDropdown
                      options={medicineOptions}
                      value={selectedValue}
                      onChange={handleSelectChange}
                      placeholder="Select Medicine"
                    />
                  </div>
      
                  {selectedValue && medicineName && (
                    <div className="account-card-item">
                      <span className="account-card-label">Medicine Name:</span>
                      <span className="account-card-value">{medicineName}</span>
                    </div>
                  )}
      
                  {pouchAmount !== null && (
                    <div className="account-card-item">
                      <span className="account-card-label">Amount in Pouch:</span>
                      <span className="account-card-value">{pouchAmount}</span>
                    </div>
                  )}
    
     
                  {pouchNumber !== null && (
                    <div className="account-card-item">
                      <span className="account-card-label">Pouch Number:</span>
                      <span className="account-card-value">{pouchNumber}</span>
                    </div>
                  )}
      
                  <div className="medicine-return-card-item">
    
                    {isUnexpectedMedicine && (
                  <button
                    type="button"
                    onClick={handleUnexpectedMedicine}
                    className='button'
                  >
                    Present but not expected
                  </button>
    
                  )}
                 </div>
    
                  </form>
                </div>
                </div>
                </div>
    
                <div className="medicine-return-table-container">
                <div className="medicine-return-table-wrapper">
                <table className="medicine-return-table">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Medicine Name</th>
                      <th>Amount</th>
                      <th>Batch</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTableData.map((item) => (
                      <tr
                        key={item.Unique_ID}
                        onClick={() => handleSelection(item.Unique_ID)}
                        className={item.Unique_ID === selectedUniqueId ? "medicine-return-selected-row" : ""}
                      >
                        <td>
                          <button className="button" onClick={() => handleButtonClick(item.Batch_Number)}>
                            Select
                          </button>
                        </td>
                        <td>{item.Medicine_Name}</td>
                        <td>{item.Amount}</td>
                        <td>{item.Batch_Number}</td>
                        <td>{new Date(item.Expiry_Date).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
                {showPopup && ( // Only render the popup if showPopup is true
                <div className="popup">
                  <div className="popup-inner">
                    <h2>Enter Amount to Remove</h2>
                    <h3>{medicineName}</h3>
                    <h3>Amount in Pouch: {pouchAmount}</h3>
                    <form onSubmit={handleSubmit}>
                      <Textinput questions={questions} />
                      <button className='button' type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                )}
              </div>
            </div>
          );
    };
    
      
export default NSMedicineRemove;