import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const MedicineBagUnpack = ({ token }) => {  // Component name changed
    const [packedPouches, setPackedPouches] = useState([]);
    const user = useSelector((state) => state.user);
    const [selectedPouchBarcode, setSelectedPouchBarcode] = useState('');

    useEffect(() => {
        const fetchPackedPouches = async () => {
            try {
                const response = await axios.get('/api/packed_pouches_in_medicine_bag', {  // API endpoint changed
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setPackedPouches(response.data);
                }
            } catch (error) {
                console.error('Error fetching packed pouches:', error);
            }
        };
        fetchPackedPouches();
    }, [token]);

    const unpackAll = async () => {
        const confirmUnpack = window.confirm(`Are you sure you want to unpack all contents of ${user.medicineBag} to station: ${user.station_barcode}?`);  // Reference changed
        if (!confirmUnpack) {
            return;
        }

        const dataToUpdate = {
            MedicineBag_Barcode: user.medicineBagBarcode,  // Reference changed
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            MedicineBag_Status: "Unpacked"  // Reference changed
        };
    
        try {
            const response = await axios.put('/api/MedicineBagHistory/updateAll/', dataToUpdate, {  // API endpoint changed
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert('All pouches unpacked successfully!');
            } else {
                alert('Error unpacking pouches.');
                console.log('UnpackAll Response:', response.data);  // Log the response data
            }
        } catch (error) {
            console.error('Error unpacking pouches:', error);
        }
    };

    const unpackSinglePouch = async () => {
        try {
            const response = await axios.get('/api/packed_pouches_in_medicine_bag/', {  // API endpoint changed
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            const pouchInMedicineBag = response.data.some(pouch =>  // Reference changed
                pouch.Pouch_Barcode === selectedPouchBarcode && 
                pouch.MedicineBag_Barcode === user.medicineBagBarcode &&  // Reference changed
                pouch.MedicineBag_Status === "Packed"  // Reference changed
            );
    
            if (!pouchInMedicineBag) {
                alert('The pouch barcode is not present in the selected medicine bag or is already unpacked!');  // Reference changed
                return;
            }
    
            const dataToUpdate = {
                Pouch_Barcode: selectedPouchBarcode,  // Ensure we're sending the pouch barcode
                Station_Unpacked: user.station_barcode,
                datetime_Unpacked: new Date().toISOString(),
                User_Unpacked: user.userBarcode,
                MedicineBag_Status: "Unpacked"  // Reference changed
            };
    
            const updateResponse = await axios.put('/api/MedicineBagHistory/updateSingle/', dataToUpdate, {  // API endpoint changed
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (updateResponse.status === 200) {
                alert(`Pouch ${selectedPouchBarcode} unpacked successfully!`);
            } else {
                alert('Error unpacking pouch.');
                console.log('UnpackSinglePouch Response:', updateResponse.data);
            }
        } catch (error) {
            console.error('Error unpacking pouch:', error);
        }
    };
    
    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Medicine Bag Unpacking</h2>  
                <div className="packing-main-content">
                    <form onSubmit={e => {
                        e.preventDefault();
                        unpackSinglePouch();
                    }}>
                        <div className="input-group">
                            <label>Enter Pouch Barcode to Unpack:</label>
                            <input 
                                type="text"
                                value={selectedPouchBarcode}
                                onChange={e => setSelectedPouchBarcode(e.target.value)}
                                placeholder="Enter Pouch Barcode"
                            />
                        </div>
                        <button type="submit" className="card-button">Unpack Pouch from Medicine Bag {user.medicineBag} to: {user.station}</button>  
                    </form>
                    <button onClick={unpackAll} className="card-button">Unpack All from Medicine Bag {user.medicineBag} to: {user.station}</button>  
                </div>
            </div>
        </div>
    );
};

export default MedicineBagUnpack;  // Export name changed
