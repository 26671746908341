import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import '../static/CSS/Header.css';

const UserDetails = ({ user }) => (
  <div>
    {/* Add more user details here */}
    <p>Username: {user.username}</p> 
    <p>Name: {user.name}</p>
    <p>Position: {user.position}</p>
    <p>User Barcode: {user.userBarcode}</p>
    <p>Station: {user.station}</p>
    <p>Station Barcode: {user.station_barcode}</p>
    <p>Pouch Number: {user.pouchNumber}</p>
    <p>Pouch Type: {user.pouchType}</p>
    <p>Pouch Barcode: {user.pouchBarcode}</p>
    <p>Carrier: {user.carrier}</p>
    <p>Carrier Barcode: {user.carrierBarcode}</p>
    <p>Medicine Bag: {user.Medicine_bag}</p> 
    <p>Medicine Bag Barcode: {user.Medicine_bagBarcode}</p>  
  </div>
);

const Menu = ({ isOpen, toggle, hasPermission }) => (
  <div className={`sidebar-bottom ${isOpen ? 'open' : ''}`}>
    <ul>
      <li><a href="/home">Home</a></li>
      {hasPermission('can_access_packing') && <li><a href="/packing">Packing</a></li>}
      {hasPermission('can_access_unpacking') && <li><a href="/unpacking">Unpacking</a></li>}
      {hasPermission('can_access_quality_assessment') && <li><a href="/quality-assessment">Quality Assessment</a></li>}
      {hasPermission('can_access_orders') && <li><a href="/orders">Orders</a></li>}
      {hasPermission('can_access_CarrierLoad') && <li><a href="/CarrierLoad">Carrier</a></li>}
      {hasPermission('can_access_MedicineBagPackPage') && <li><a href="/MedicineBagLoad">Medicine Bags</a></li>}
      {hasPermission('can_access_EditPages') && <li><a href="/EditPages">Edit</a></li>}
      {hasPermission('can_access_settings') && <li><a href="/settings">Settings</a></li>}
      {hasPermission('can_access_stock') && <li><a href="/stock">Stock</a></li>}
      {hasPermission('can_access_barcodes') && <li><a href="/barcodes">Barcodes</a></li>}
      {hasPermission('can_access_reports') && <li><a href="/reports">Reports</a></li>}
      {hasPermission('can_access_DrugAdministration') && <li><a href="/DrugAdministration">Drug Administration</a></li>}

      <li><a href="/ErrorHandlingPage">Error Handling</a></li>
      <li><a href="/AccountPage">Account</a></li>
      <li><a href="/login">Logout</a></li>
    </ul>
  </div>
);

const Modal = ({ isOpen, toggle, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal'>
      <div className='modal-content'>
        <button onClick={toggle}>Close</button>
        {children}
      </div>
    </div>
  );
};

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const permissions = useSelector((state) => state.permissions);  
  const location = useLocation();
  const hasPermission = codename => permissions.includes(codename);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleUserDetails = () => {
    setUserDetailsOpen(!userDetailsOpen);
  };

  return (
    <>
      <header className="header">
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <nav className="header-nav">
          <a href="/home">Home</a>
          {hasPermission('can_access_packing') && <a href="/packing">Packing</a>}
          {hasPermission('can_access_quality_assessment') && <a href="/quality-assessment">Quality Assessment</a>}
          {hasPermission('can_access_unpacking') && <a href="/unpacking">Unpacking</a>}
          {hasPermission('can_access_orders') && <a href="/orders">Orders</a>}
        </nav>
        <button onClick={toggleUserDetails} className="User-button reset-button-styles">Show user details</button>
      </header>
      {menuOpen && <Menu isOpen={menuOpen} toggle={toggleMenu} hasPermission={hasPermission} />}
      <Modal isOpen={userDetailsOpen} toggle={toggleUserDetails}>
        <UserDetails user={user} />
      </Modal>
    </>
  );
};

export default Header;
