// src/App.js
import './static/CSS/App.css';
import './static/CSS/EditTable.css';
import './static/CSS/Tabs.css';
import './static/CSS/Form.css';
import './static/CSS/MedicineUpload.css';
import './static/CSS/Visulisation.css';


import React from 'react';
import EditMedicine from './pages/EditMedicine';
import EditMedicineBatch from './pages/EditMedicineBatch';
import EditUsers from './pages/EditUsers';
import EditPouch from './pages/EditPouch';
import EditPages from './pages/Editpages';
import EditCarrier from './pages/EditCarrier';
import EditStation from './pages/EditStation';
import HomePage from './pages/HomePage';
import LoginForm from './pages/LoginForm';
import EditStock from './pages/Stock';
import StockCheckpage from './pages/StockCheck';
import Pack from './pages/Pack';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Packing from './pages/Packing';
import Unpacking from './pages/Unpacking';
import Unpack from './pages/Unpack';
import QA from './pages/QA';
import Barcode from './pages/Barcode';
import Missingreasons from './pages/Missingreasons';
import { useSelector } from 'react-redux';
import Reports from  './pages/Reports';
import Orderspage from  './pages/Orderspage';
import withAuthCheck from './components/AuthCheck/withAuthCheck';
import EditPouchContents from './pages/EditPouch';
import EditPouchType from './pages/EditPouchType';
import PriceReport from './pages/Reports/PriceReport'
import PouchStatusReport from './pages/Reports/PouchStatusReport'
import PouchTypeSummaryReport from './pages/Reports/PouchTypeSummaryReport'
import PouchesPackedReport from './pages/Reports/PouchesPackedReport'
import DrugAdministrationReportPage from './pages/Reports/DrugAdministrationReportPage'
import PackedPouchesInMedicineBagReport from './pages/Reports/PackedPouchesInMedicineBagReport';
import GlobalImportExport from './pages/Globalimportexport'
import PouchDataWithDateReport from './pages/Reports/PouchDataWithDateReport'
import StockHistoryDataWithDateReport from './pages/Reports/StockHistoryDataWithDateReport'
import MedicineSummaryReport from './pages/Reports/MedicineSummaryReport';
import MedicineBatchSummaryReport from './pages/Reports/MedicineBatchSummaryReport';
import CarrierLoad from './pages/CarrierLoad';
import MedicineBagLoad from './pages/MedicineBagLoad';
import AllPoucheContentsReport from './pages/Reports/AllPoucheContentsReport';
import CarrierPackPage from './pages/CarrierPackPage';
import CarrierStorePage from './pages/CarrierStorePage';
import CarrierUnpackPage from './pages/CarrierUnpackPage';
import CarrierPackedContentsReport from './pages/Reports/CarrierPackedContentsReport';
import AccountPage from './pages/AccountPage';
import Settings from './pages/Settings'; 
import EditUserPosition from './pages/EditUserPosition';
import MedicineBagPackPage from './pages/MedicineBagPackPage';
import MedicineBagStorePage from './pages/MedicineBagStorePage';
import MedicineBagUnpackPage from './pages/MedicineBagUnpackPage';
import DrugAdministration from './pages/DrugAdministration';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgotUsername from './pages/ForgotUsername';
import ErrorHandlingPage from './pages/ErrorHandlingPage'; 
import LandingPage from './pages/LandingPage/LandingPage'; 
import ContactPage from './pages/LandingPage/ContactPage';

function App() {
  const ProtectedRoutesWithAuthCheck = withAuthCheck(ProtectedRoutes, (state) => state.user.token);

  return (
    <div className="App">
      <Router>
        <div className="main-content">
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/LandingPage" element={<LandingPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/*" element={<ProtectedRoutesWithAuthCheck />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/edit-medicine" element={<EditMedicine />} />
            <Route path="/edit-medicine-batch" element={<EditMedicineBatch />} />
            <Route path="/edit-user" element={<EditUsers />} />
            <Route path="/edit-pouch" element={<EditPouch />} />
            <Route path="/edit-carrier" element={<EditCarrier />} />
            <Route path="/edit-station" element={<EditStation />} />
            <Route path="/edit-UserPosition" element={<EditUserPosition />} />
            <Route path="/EditPages" element={<EditPages />} />
            <Route path="/stock" element={<EditStock />} />
            <Route path="/packing" element={<Packing />} />
            <Route path="/pack" element={<Pack />} />
            <Route path="/unpacking" element={<Unpacking />} />
            <Route path="/unpack" element={<Unpack />} />
            <Route path="/Missingreasons" element={<Missingreasons />} />    
            <Route path="/quality-assessment" element={<QA />} /> 
            <Route path="/barcodes" element={<Barcode />} /> 
            <Route path="/Reports" element={<Reports />} /> 
            <Route path="/stockcheck" element={<StockCheckpage />} />
            <Route path="/orders" element={<Orderspage />} />
            <Route path="/edit-pouch-contents" element={<EditPouchContents />} />
            <Route path="/edit-pouch-type" element={<EditPouchType />} />
            <Route path="/PriceReport" element={<PriceReport />} />
            <Route path="/PouchTypeSummaryReport" element={<PouchTypeSummaryReport />} />
            <Route path="/PouchStatusReport" element={<PouchStatusReport />} />
            <Route path="/PouchesPackedReport" element={<PouchesPackedReport />} />
            <Route path="/PouchDataWithDateReport" element={<PouchDataWithDateReport />} />
            <Route path="/StockHistoryDataWithDateReport" element={<StockHistoryDataWithDateReport />} />
            <Route path="/MedicineSummaryReport" element={<MedicineSummaryReport />} />
            <Route path="/AllPoucheContentsReport" element={<AllPoucheContentsReport />} />
            <Route path="/MedicineBatchSummaryReport" element={<MedicineBatchSummaryReport />} />
            <Route path="/CarrierLoad" element={<CarrierLoad />} />
            <Route path="/CarrierPackPage" element={<CarrierPackPage />} />
            <Route path="/CarrierStorePage" element={<CarrierStorePage />} />
            <Route path="/CarrierUnpackPage" element={<CarrierUnpackPage />} />
            <Route path="/CarrierPackedContentsReport" element={<CarrierPackedContentsReport />} />
            <Route path="/AccountPage" element={<AccountPage />} />
            <Route path="/Settings" element={<Settings />} />  
            <Route path="/MedicineBagLoad" element={<MedicineBagLoad />} />
            <Route path="/MedicineBagPackPage" element={<MedicineBagPackPage />} />  
            <Route path="/MedicineBagStorePage" element={<MedicineBagStorePage />} />  
            <Route path="/MedicineBagUnpackPage" element={<MedicineBagUnpackPage />} />  
            <Route path="/DrugAdministration" element={<DrugAdministration />} />  
            <Route path="/forgot-username" element={<ForgotUsername />} /> 
            <Route path="/ResetPasswordPage" element={<ResetPasswordPage />} /> 
            <Route path="/ErrorHandlingPage" element={<ErrorHandlingPage />} /> 
            <Route path="/DrugAdministrationReportPage" element={<DrugAdministrationReportPage />} /> 
            <Route path="/PackedPouchesInMedicineBagReport" element={<PackedPouchesInMedicineBagReport />} />
            <Route path="/GlobalImportExport" element={<GlobalImportExport />} />
            <Route path="/contact" element={<ContactPage />} />

          </Routes>
        </div>
      </Router>
    </div>
  );
}

const ProtectedRoutes = () => {
  const user = useSelector((state) => state.user);

  if (!user.username) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route path="/home" element={<HomePage />} />
      <Route path="/edit-medicine" element={<EditMedicine />} />
      <Route path="/edit-medicine-batch" element={<EditMedicineBatch />} />
      <Route path="/edit-user" element={<EditUsers />} />
      <Route path="/edit-pouch" element={<EditPouch />} />
      <Route path="/edit-carrier" element={<EditCarrier />} />
      <Route path="/edit-station" element={<EditStation />} />
    </Routes>
  );
};

export default App;