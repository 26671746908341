import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Textinput from '../Styling components/Textinput';

const MedicineRemove = ({ token, onUpdate }) => {
  const [medicineBarcode, setMedicineBarcode] = useState('');
  const [amount, setAmount] = useState('');
  const [pouchAmount, setPouchAmount] = useState(null);
  const [isMedicineExpected, setIsMedicineExpected] = useState(true);
  const [uniqueIds, setUniqueIds] = useState([]);

  const userBarcode = useSelector((state) => state.user.userBarcode);
  const stationBarcode = useSelector((state) => state.user.station_barcode);
  const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
  const name = useSelector((state) => state.user.name);
  const [barcodeMedicineName, setBarcodeMedicineName] = useState('');

  // Reset form state
  const handleRefresh = () => {
    setMedicineBarcode('');
    setAmount('');
    setPouchAmount(null);
    setIsMedicineExpected(true);
  };

// Fetch medicine data and pouch contents
useEffect(() => {
  const fetchPouchContents = async () => {
    if (!medicineBarcode || !token || !pouchBarcode) {
      console.log('Missing data: token, pouchBarcode, or medicineBarcode is not set.');
      return;
    }

    try {
      const response = await axios.get(`/api/pouch_contents_from_pouch_history/${pouchBarcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const medicineItems = response.data.filter(item => item.Drug_Barcode === medicineBarcode);

      const ids = medicineItems.map(item => item.unique_id);

      setUniqueIds(ids);

      if (medicineItems.length > 0) {
        setPouchAmount(medicineItems.length);
        setIsMedicineExpected(true);
        // If you want to set the name of the medicine, assuming it's the same for all items with the same barcode
        setBarcodeMedicineName(medicineItems[0].Medicine_Name.trim());
      } else {
        setPouchAmount(0);
        setIsMedicineExpected(false);
        setBarcodeMedicineName('');
      }
    } catch (error) {
      console.error('Error fetching pouch contents:', error);
    }
  };

  fetchPouchContents();
}, [medicineBarcode, token, pouchBarcode]);



  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (parseInt(amount) <= 0) {
      alert("The inputted amount must be greater than zero.");
      return;
    }
  
    if (parseInt(amount) > pouchAmount) {
      alert("The inputted amount is more than the amount in the pouch. Operation cancelled.");
      return;
    }
  
    try {
      // Fetch MedicineInPouch data by pouch_barcode and medicine_barcode using the new filter view
      const medicineInPouchResponse = await axios.get(`/api/MedicineInPouch/filter/?pouch_barcode=${pouchBarcode}&medicine_barcode=${medicineBarcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      const medicineInPouchData = medicineInPouchResponse.data;
    
      if (!medicineInPouchData || medicineInPouchData.length === 0) {
        alert("No medicine found in the pouch with the specified barcode.");
        return;
      }
    
      // Assuming there's only one entry for a specific medicineBarcode and pouchBarcode
      const pouchData = medicineInPouchData[0];
      const newAmount = pouchData.amount - parseInt(amount);
    
      // If the new amount is zero, delete the record
      if (newAmount === 0) {
        await axios.delete(`/api/MedicineInPouch/delete/${pouchData.unique_id}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (newAmount > 0) {
        // Update the record with the new amount
        await axios.put(`/api/MedicineInPouch/update/${pouchData.unique_id}/`, {
          drug_barcode: medicineBarcode,
          pouch_barcode: pouchBarcode,
          amount: newAmount,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        alert("The inputted amount is more than the amount in the pouch. Operation cancelled.");
        return;
      }
  
      // Update the pouch history and stock holding history
      let remainingAmount = parseInt(amount);
  
      for (const id of uniqueIds) {
        if (remainingAmount <= 0) break;
  
        await axios.put(`/api/update_pouch_history/${id}/`, {
          user_unpacked: userBarcode,
          datetime_unpacked: new Date().toISOString(),
          status: 'Unpacked',
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        remainingAmount -= 1;
      }
  
      await axios.post('/api/StockHoldingHistory/create/', {
        location: stationBarcode,
        user_barcode: userBarcode,
        drug_barcode: medicineBarcode,
        transaction: 'Pouch to Stock',
        amount: amount - remainingAmount,
        datetime_transaction: new Date().toISOString(),
        comments: `Unpacked by ${name}`,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      alert("Medicine removed successfully");
      handleRefresh();
      if (typeof onUpdate === 'function') {
        onUpdate();
      }
    } catch (error) {
      console.error("Error removing medicine:", error);
      alert("Error removing medicine");
    }
  };
  
  

  const questions = [
    {
      type: "text",
      value: medicineBarcode,
      handleChange: (e) => setMedicineBarcode(e.target.value),
      text: "Medicine Barcode:"
    },
  ];


  return (
    <div className="medicine-return-container">
      <div className="form-container">
        <div className="medicine-return-card">
          <div className="medicine-return-card-header">
            <h2>Medicine to Remove</h2>
          </div>
          <div className="medicine-return-card-body">
            <form onSubmit={handleSubmit} className="medicine-return-form">
              <Textinput questions={questions} />
  
              {medicineBarcode && isMedicineExpected && (
                <>
                  <div className="account-card-item">
                    <span className="account-card-label">Medicine Name:</span>
                    <span className="account-card-value">{barcodeMedicineName}</span>
                  </div>
                  <div className="account-card-item">
                    <span className="account-card-label">Amount in Pouch:</span>
                    <span className="account-card-value">{pouchAmount}</span>
                  </div>
                  <div className="account-card-item">
                    <label htmlFor="amount" className="account-card-label">Amount to Remove:</label>
                    <input
                      type="number"
                      id="amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="account-card-input"
                    />
                  </div>
                </>
              )}
  
              {!isMedicineExpected && (
                <div className="account-card-item">
                  <span className="account-card-error">
                    *** This medicine is not expected in this pouch ***
                  </span>
                </div>
              )}
  
              <button type="submit" className="button">Confirm</button>
              <button type="button" onClick={handleRefresh} className="button">Refresh</button>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineRemove;
