import React from 'react';
import axios from 'axios';

// New FillTemplate Button
const FillTemplateButton = ({pouchBarcode, token}) => {
    const GenerateFilledDocument = async () => {
        try {
            const response = await axios.get(
                `/api/generate_pouch_paperwork_fill/${pouchBarcode}/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: 'blob',  // Tell axios to download the file
                }
            );
    
            const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
    
            // Extract the filename from the Content-Disposition header
            const filename = response.headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '');
    
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); 
    
            alert('Filled Word document successfully generated!');
        } catch (error) {
            console.error('An error occurred while generating the filled Word document:', error);
            alert('Failed to generate filled Word document');
        }
    };

    return (
        <button className="button" onClick={GenerateFilledDocument}>
            Fill Template
        </button>
    );
};

export default FillTemplateButton;