import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';  

const MedicineBagTable = ({ token }) => {  
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);  
  const medicineBagBarcode = user.Medicine_bagBarcode;  

  useEffect(() => {
    // Fetch all packed pouches for the medicine bag
    const fetchPackedPouches = async () => {
      try {
        const response = await axios.get(`api/packed_pouches_in_medicine_bag?Medicine_bag_Barcode=${medicineBagBarcode}`, {  
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error('Error fetching packed pouches:', error);
      }
    };

    if (medicineBagBarcode) { 
      fetchPackedPouches();
    }
  }, [token, medicineBagBarcode]);  

  const displayColumns = ['Pouch_Number', 'MedicineBag_Status', 'medicine_bag_number'];  // Columns you want to display, changed to Medicine_bag
  // Define column titles mapping
  const columnTitles = {
    'Pouch_Number': 'Pouch Number',
    'MedicineBag_Status': 'Medicine Bag Status',
    'medicine_bag_number': 'Medicine Bag Number'
  };

  return (
    <div>
      <Table data={data} columns={displayColumns} columnTitles={columnTitles} />
    </div>
  );
};

export default MedicineBagTable;  
