import React, { useState } from 'react';
import Header from '../components/header';
import MedicineBagPack from '../components/Medicine_Bag/MedicineBagPack'; 
import NSMedicineBagPack from '../components/Medicine_Bag/NSMedicine_BagPack'; // Assuming this is the correct import path
import MedicineBagTable from '../components/Medicine_Bag/MedicineBagTable';

const token = localStorage.getItem("token");

const MedicineBagPackPage = () => {
    const [showNSMedicineBagPack, setShowNSMedicineBagPack] = useState(false);

    return (
        <div className='main-content'>
            <Header token={token}/>
            <button className='button' onClick={() => setShowNSMedicineBagPack(!showNSMedicineBagPack)}>
                {showNSMedicineBagPack ? 'Switch to MedicineBagPack' : 'Switch to NSMedicineBagPack'}
            </button>

            {showNSMedicineBagPack ? <NSMedicineBagPack token={token}/> : <MedicineBagPack token={token}/>}
            <MedicineBagTable token={token}/>
        </div>
    );
};

export default MedicineBagPackPage;
