import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const NSCarrierUnpack = ({ token }) => {
    const [pouchNumbers, setPouchNumbers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPouchNumbers, setFilteredPouchNumbers] = useState([]);
    const [dropdownPouchNumber, setDropdownPouchNumber] = useState('');
    const user = useSelector((state) => state.user);
  
        useEffect(() => {
            const fetchPouchNumbers = async () => {
                try {
                    const response = await axios.get('/api/packed_pouches_in_carrier/', {
                        params: {
                            Carrier_Barcode: user.carrierBarcode
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.status === 200) {
                        setPouchNumbers(response.data.map(pouch => pouch.Pouch_Number)); // Assuming the data returned has a Pouch_Number field
                    }
                } catch (error) {
                    console.error('Error fetching pouch numbers:', error);
                }
            };
    
            fetchPouchNumbers();
        }, [token, user.carrierBarcode]); // Added user.carrierBarcode as a dependency
    

    // Unpack All Pouches
// Unpack All Pouches
const unpackAll = async () => {
    const confirmUnpack = window.confirm(`Are you sure you want to unpack all contents of ${user.carrier}?`);
    if (!confirmUnpack) {
        return;
    }

    const dataToUpdate = {
        Carrier_Barcode: user.carrierBarcode,  // Add the Carrier_Barcode
        Station_Unpacked: user.station_barcode,
        datetime_Unpacked: new Date().toISOString(),
        User_Unpacked: user.userBarcode,
        Carrier_Status: "Unpacked"
    };

    try {
        const response = await axios.put('/api/CarrierHistory/updateAll/', dataToUpdate, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200) {
            alert('All pouches unpacked successfully!');
        } else {
            alert('Error unpacking pouches.');
            console.log('UnpackAll Response:', response.data);  // Log the response data
        }
    } catch (error) {
        console.error('Error unpacking pouches:', error);
    }
};


    useEffect(() => {
        setFilteredPouchNumbers(
            pouchNumbers.filter(number => 
                number.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, pouchNumbers]);

    const getPouchBarcodeFromNumber = async (pouchNumber) => {
        try {
            const response = await axios.get('/api/packed_pouches_in_carrier/', {
                params: {
                    Carrier_Barcode: user.carrierBarcode
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            const pouch = response.data.find(p => p.Pouch_Number === pouchNumber);
            return pouch ? pouch.Pouch_Barcode : null;
        } catch (error) {
            console.error('Error fetching pouch barcode:', error);
            return null;
        }
    };
    

    // Unpack Single Pouch
    const partialUnpack = async () => {
        // Get Pouch_Barcode from pouch_number
        const pouchBarcode = await getPouchBarcodeFromNumber(searchTerm);

        if (!pouchBarcode) {
            alert('Error fetching pouch barcode. Please try again.');
            return;
        }

        const dataToUpdate = {
            Pouch_Barcode: pouchBarcode,
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            Carrier_Status: "Unpacked"
        };

        try {
            const response = await axios.put('/api/CarrierHistory/updateSingle/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert(`Pouch ${searchTerm} (Barcode: ${pouchBarcode}) unpacked successfully!`);
            } else {
                alert('Error unpacking pouch.');
            }
        } catch (error) {
            console.error('Error unpacking pouch:', error);
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Carrier Unpacking</h2>
                <div className="packing-main-content">
                    <form onSubmit={e => {
                        e.preventDefault();
                        partialUnpack();
                    }}>
                        <div className="input-group">
                            <label htmlFor="pouchNumberSearch">Pouch Number</label>
                            <input 
                                id="pouchNumberSearch"
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search Pouch Number"
                            />
                            <select 
                                id="pouchNumberDropdown"
                                value={dropdownPouchNumber}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setDropdownPouchNumber(e.target.value);
                                }}
                            >
                                <option value="Search for Pouch">Select Pouch</option>
                                {filteredPouchNumbers.map((number, index) => (
                                    <option key={index} value={number}>
                                        {number}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="card-button">Unpack Selected Pouch from Carrier {user.carrier}</button>
                    </form>
                    <button onClick={unpackAll} className="card-button">Unpack All from Carrier {user.carrier}</button>
                </div>
            </div>
        </div>
    );
};

export default NSCarrierUnpack;
