import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const NSMedicineBagUnpack = ({ token }) => {
    const [pouchNumbers, setPouchNumbers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPouchNumbers, setFilteredPouchNumbers] = useState([]);
    const [dropdownPouchNumber, setDropdownPouchNumber] = useState('');
    const user = useSelector((state) => state.user);
  
    useEffect(() => {
        const fetchPouchNumbers = async () => {
            try {
                const response = await axios.get('/api/packed_pouches_in_medicine_bag/', {
                    params: {
                        Medicine_Bag_Barcode: user.medicineBagBarcode
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setPouchNumbers(response.data.map(pouch => pouch.Pouch_Number));
                }
            } catch (error) {
                console.error('Error fetching pouch numbers:', error);
            }
        };

        fetchPouchNumbers();
    }, [token, user.medicineBagBarcode]);

    const unpackAll = async () => {
        const confirmUnpack = window.confirm(`Are you sure you want to unpack all contents of ${user.medicineBag}?`);
        if (!confirmUnpack) {
            return;
        }

        const dataToUpdate = {
            Medicine_Bag_Barcode: user.medicineBagBarcode,
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            Medicine_Bag_Status: "Unpacked"
        };

        try {
            const response = await axios.put('/api/MedicineBagHistory/updateAll/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert('All pouches unpacked successfully!');
            } else {
                alert('Error unpacking pouches.');
            }
        } catch (error) {
            console.error('Error unpacking pouches:', error);
        }
    };

    useEffect(() => {
        setFilteredPouchNumbers(
            pouchNumbers.filter(number => 
                number.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, pouchNumbers]);

    const getPouchBarcodeFromNumber = async (pouchNumber) => {
        try {
            const response = await axios.get('/api/packed_pouches_in_medicine_bag/', {
                params: {
                    Medicine_Bag_Barcode: user.medicineBagBarcode
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            const pouch = response.data.find(p => p.Pouch_Number === pouchNumber);
            return pouch ? pouch.Pouch_Barcode : null;
        } catch (error) {
            console.error('Error fetching pouch barcode:', error);
            return null;
        }
    };

    const partialUnpack = async () => {
        const pouchBarcode = await getPouchBarcodeFromNumber(searchTerm);

        if (!pouchBarcode) {
            alert('Error fetching pouch barcode. Please try again.');
            return;
        }

        const dataToUpdate = {
            Pouch_Barcode: pouchBarcode,
            Station_Unpacked: user.station_barcode,
            datetime_Unpacked: new Date().toISOString(),
            User_Unpacked: user.userBarcode,
            Medicine_Bag_Status: "Unpacked"
        };

        try {
            const response = await axios.put('/api/MedicineBagHistory/updateSingle/', dataToUpdate, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert(`Pouch ${searchTerm} (Barcode: ${pouchBarcode}) unpacked successfully!`);
            } else {
                alert('Error unpacking pouch.');
            }
        } catch (error) {
            console.error('Error unpacking pouch:', error);
        }
    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Medicine Bag Unpacking</h2>
                <div className="packing-main-content">
                    <form onSubmit={e => {
                        e.preventDefault();
                        partialUnpack();
                    }}>
                        <div className="input-group">
                            <label htmlFor="pouchNumberSearch">Pouch Number</label>
                            <input 
                                id="pouchNumberSearch"
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search Pouch Number"
                            />
                            <select 
                                id="pouchNumberDropdown"
                                value={dropdownPouchNumber}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setDropdownPouchNumber(e.target.value);
                                }}
                            >
                                <option value="Search for Pouch">Select Pouch</option>
                                {filteredPouchNumbers.map((number, index) => (
                                    <option key={index} value={number}>
                                        {number}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="card-button">Unpack Selected Pouch from Medicine Bag {user.medicineBag}</button>
                    </form>
                    <button onClick={unpackAll} className="card-button">Unpack All from Medicine Bag {user.medicineBag}</button>
                </div>
            </div>
        </div>
    );
};

export default NSMedicineBagUnpack;
