import React, { useState } from 'react';
import Header from '../components/header';
import CarrierUnpack from '../components/CarrierTrack/CarrierUnpack';
import NSCarrierUnpack from '../components/CarrierTrack/NSCarrierUnpack';  // Import the NSCarrierUnpack component
import CarrierTable from '../components/CarrierTrack/CarrierTable';

const token = localStorage.getItem("token");

const CarrierUnpackPage = () => {
    const [isShowingNSCarrierUnpack, setIsShowingNSCarrierUnpack] = useState(false);  // State variable to determine which component to show

    return (
        <div className='main-content'>
            <Header token={token}/>
            <button className="button" onClick={() => setIsShowingNSCarrierUnpack(!isShowingNSCarrierUnpack)}>
                {isShowingNSCarrierUnpack ? "Switch to Barcodes" : "Switch to Manual"}
            </button>
            
            {isShowingNSCarrierUnpack ? <NSCarrierUnpack token={token}/> : <CarrierUnpack token={token}/>}
            <CarrierTable token={token}/>
        </div>
    );
};

export default CarrierUnpackPage;
