import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DynamicForm from '../DynamicForm/DynamicForm';

const MedicineBatchForm = ({ token, selectedMedicine }) => {
  const [medicine_name, setMedicineName] = useState('');
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const [batch_number, setBatchNumber] = useState('');
  const [expiry_date, setExpiryDate] = useState('');
  const [medicine_pricing, setMedicinePricing] = useState('');
  const [drug_barcode, setDrugBarcode] = useState('');
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);
  const [supplier, setSupplier] = useState('');
  const [pack_size, setPackSize] = useState('');
  const [medicines, setMedicines] = useState([]);


  useEffect(() => {
    fetchMedicines();
    if (selectedMedicine) {
      setMedicineName(selectedMedicine['Medicine name'],);
      setBatchNumber(selectedMedicine['Batch Number']);
      if (selectedMedicine['Expiry Date']) {
        const [day, month, year] = selectedMedicine['Expiry Date'].split('/');
        const expiryDateObj = new Date(`${year}-${month}-${day}`);
        if (!isNaN(expiryDateObj)) {
            const formattedExpiryDate = expiryDateObj.toISOString().split('T')[0];
            setExpiryDate(formattedExpiryDate);
        } else {
            console.error('Invalid expiry date:', selectedMedicine['Expiry Date']);
        }
    }
    
      setMedicinePricing(selectedMedicine['Medicine Pricing']);
      setDrugBarcode(selectedMedicine['Drug Barcode']);
      setPackSize(selectedMedicine['Pack Size']);
      setSupplier(selectedMedicine['Supplier']);
      setSelectedMedicineId(selectedMedicine.Unique_id);
    }
  }, [selectedMedicine]);


  const handleGenerateBarcode = async (setDrugBarcode) => {
    try {
      const response = await fetch('/api/generate_unique_barcode/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setDrugBarcode(data.unique_barcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const now = new Date();
    const nowWithOffset = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const isoStringWithOffset = nowWithOffset.toISOString();
  
    try {
      const response = await fetch('/api/MedicineBatch/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          medicine_name: medicine_name,
          batch_number: batch_number,
          expiry_date: expiry_date,
          medicine_pricing: medicine_pricing,
          drug_barcode: String(drug_barcode),
          added_by: userBarcode,
          date_added: new Date().toISOString().split('T')[0],
          supplier: supplier,
          pack_size: pack_size,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Medicine batch created:', data);
        window.location.reload();
      } else {
        console.error('Error creating medicine batch:', response.statusText);
        const errorData = await response.json();
        console.log('Error data:', errorData);
      }
    } catch (error) {
      console.error('Error creating medicine batch:', error);
    }
  };
  
  

    const handleRefresh = () => {
        setMedicineName('');
        setBatchNumber('');
        setExpiryDate('');
        setMedicinePricing('');
        setDrugBarcode('');
        setSupplier('');
        setPackSize('');
        setSelectedMedicineId(null);
    };

    const handleDeleteConfirmation = () => {
        if (window.confirm(`Are you sure you want to delete batch number ${batch_number} for ${medicine_name}?`)) {
        handleDelete();
        }
    };

    const handleUpdate = async () => {
      try {
        const response = await fetch(`/api/MedicineBatch/update/${selectedMedicineId}/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            medicine_name: medicine_name,
            batch_number: batch_number,
            expiry_date: expiry_date,
            medicine_pricing: medicine_pricing,
            drug_barcode: drug_barcode,
            Added_by: userBarcode,
            date_added: new Date().toISOString().split('T')[0], 
            supplier: supplier,
            pack_size: pack_size,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Medicine batch updated:', data);
          window.location.reload();
        } else {
          console.error('Error updating medicine batch:', response.statusText);
          const errorData = await response.json();
          console.log('Error details:', errorData);
        }
      } catch (error) {
        console.error('Error updating medicine batch:', error);
      }
  };
  
  
  
    const handleDelete = async () => {
        try {
          const response = await fetch(`/api/MedicineBatch/delete/${selectedMedicineId}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          

        if (response.ok) {
            console.log('Medicine batch deleted');
            window.location.reload();
        } else {
            console.error('Error deleting medicine batch:', response.statusText);
        }
        } catch (error) {
        console.error('Error deleting medicine batch:', error);
        }
    };

    
    const fetchMedicines = async () => {
      try {
        const response = await fetch('/api/Medicine/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data)) {
            setMedicines(data);
          } else {
            console.error('Expected an array but got:', data);
          }
        } else {
          console.error('Error fetching medicines:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching medicines:', error);
      }
    };
    

    return (
      <DynamicForm
        title="Add New Medicine Batch"
        questions={[
          {
            type: 'select',
            text: 'Medicine Name',
            styled: true,
            options: medicines.map(medicine => ({ value: medicine.medicine_name, label: medicine.medicine_name })),
            value: medicine_name,
            handleChange: (e) => setMedicineName(e.target.value)
          },
          {
            type: 'text',
            text: 'Batch Number',
            value: batch_number,
            handleChange: (e) => setBatchNumber(e.target.value),
          },
          {
            type: 'date',
            text: 'Expiry Date',
            value: expiry_date,
            handleChange: (e) => setExpiryDate(e.target.value),
          },
          {
            type: 'text',
            text: 'Medicine Pricing',
            value: medicine_pricing,
            handleChange: (e) => setMedicinePricing(e.target.value),
          },
          {
            type: 'text',
            text: 'Drug Barcode',
            value: drug_barcode,
            handleChange: (e) => setDrugBarcode(e.target.value),
            isLocked: true 
          },
          {
            type: 'text',
            text: 'Supplier',
            value: supplier,
            handleChange: (e) => setSupplier(e.target.value),
          },
          {
            type: 'number',
            text: 'Pack Size',
            value: pack_size,
            handleChange: (e) => setPackSize(e.target.value),
          },
   
          ...(!drug_barcode ? [{
            text: 'Generate Barcode',
            type: 'generateBarcode'
          }] : [])
        ]}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleUpdate={handleUpdate}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleGenerateBarcode={handleGenerateBarcode} 
        setDrugBarcode={setDrugBarcode} 
        selectedId={selectedMedicineId}
        showSearchBar={true}
      />
    );
  }

export default MedicineBatchForm;