import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const NSCarrierPack = ({ token }) => {

    const [pouchNumbers, setPouchNumbers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPouchNumbers, setFilteredPouchNumbers] = useState([]);
    const [dropdownPouchNumber, setDropdownPouchNumber] = useState('');
    const [pouchStatus, setPouchStatus] = useState('');
    const [confirmAction, setConfirmAction] = useState(false);
    const user = useSelector((state) => state.user);
    const [selectedPouchBarcode, setSelectedPouchBarcode] = useState('');

    useEffect(() => {
        const fetchPouchNumbers = async () => {
            try {
                const response = await axios.get('/api/Pouch/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setPouchNumbers(response.data.map(pouch => pouch.pouch_number));
                }
            } catch (error) {}
        };

        fetchPouchNumbers();
    }, [token]);


    useEffect(() => {
        let matchingPouch;  // Declare it here
    
        const fetchData = async () => {
            try {
                const barcodeResponse = await axios.get(`/api/Pouch/?pouchNumber=${dropdownPouchNumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                // Find the pouch with the selected pouch number
                matchingPouch = barcodeResponse.data.find(pouch => pouch.pouch_number === dropdownPouchNumber);  // Assign without using const
        
                if (matchingPouch && matchingPouch.pouch_barcode) {
                    setSelectedPouchBarcode(matchingPouch.pouch_barcode);
                }
        
            } catch (error) {}
        
            if (matchingPouch && matchingPouch.pouch_barcode) {
                try {
                    const statusResponse = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${matchingPouch.pouch_barcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (statusResponse.data[0] && statusResponse.data[0].Pouch_Status) {
                        setPouchStatus(statusResponse.data[0].Pouch_Status);
                    } else {
                        setPouchStatus('Status Not Available');
                    }
                } catch (error) {}
            }
        };
        
        if (dropdownPouchNumber) {
            fetchData();
        }
    }, [dropdownPouchNumber, token]);
    
    

    useEffect(() => {
        setFilteredPouchNumbers(
            pouchNumbers.filter(number => 
                number.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, pouchNumbers]);

    const handleProceed = () => {
        setConfirmAction(false);
        submitCarrierHistory();
    };

    const handleCancel = () => {
        setConfirmAction(false);
        setDropdownPouchNumber('');
    };

    const submitCarrierHistory = async () => {
        const carrierHistoryData = {
            Carrier_Barcode: user.carrierBarcode,
            Pouch_Barcode: selectedPouchBarcode,
            Station_Barcode_Start: user.station_barcode,
            datetime_start: new Date().toISOString(),
            amount: 1,
            User_Packed: user.userBarcode,
            Carrier_Status: "Packed"
        };

        try {
            const response = await axios.post('/api/CarrierHistory/create/', carrierHistoryData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200 || response.status === 201) {
                alert('Carrier history added successfully!');
            } else {
                alert('Error adding carrier history.');
            }
        } catch (error) {}
    };


    const checkPouchExistsInCarrierHistory = async () => {

        try {
            const response = await axios.get(`/api/CarrierHistory/?pouchBarcode=${selectedPouchBarcode}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
    
            for (let pouchData of response.data) {
                
                if (pouchData.Pouch_Barcode === selectedPouchBarcode && pouchData.Carrier_Status === 'Packed') {

                    const carrierResponse = await axios.get(`/api/Carrier/?Carrier_Barcode=${pouchData.Carrier_Barcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const carrierData = carrierResponse.data && carrierResponse.data[0];
                    return {
                        exists: true,
                        carrierNumber: carrierData ? carrierData.carrier_number : "Unknown"
                    };
                }
            }
            return { exists: false };
        } catch (error) {
            console.error('Error checking pouch in CarrierHistory:', error);
            return { exists: false };
        }
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        let fetchedPouchBarcode = '';
        try {
            const response = await axios.get(`/api/PouchNumber/${dropdownPouchNumber}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data && response.data.pouch_barcode) {
                fetchedPouchBarcode = response.data.pouch_barcode;
            }
        } catch (error) {
            alert('Error fetching pouch barcode. Please try again.');
            return;
        }
        const pouchExistsInfo = await checkPouchExistsInCarrierHistory(fetchedPouchBarcode);
    
        
        if (pouchExistsInfo.exists) {
            alert(`This pouch is currently being shown as packed in the ${pouchExistsInfo.carrierNumber}`);
            setSearchTerm('');  // Clear the search box
            return;
        }  

        if (pouchStatus === 'Unpacked' || pouchStatus === 'Status Not Available') {
            alert("This is showing as unpacked. Please ensure it is packed");
            setSearchTerm('');  // Clear the search box
            return;
        }

        if (pouchStatus === 'QAed') {
            submitCarrierHistory();
            return;
        }

        if (pouchStatus === 'Partially QAed' || pouchStatus === 'Packed') {
            setConfirmAction(true);
            return;
        }

  
      

    };

    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Pack Carrier</h2>
                <div className="packing-main-content">
                    {confirmAction ? (
                        <div>
                            <p>This is showing as {pouchStatus} and has not been QAed. Are you sure you want to continue?</p>
                            <button onClick={handleProceed} className="card-button">Yes</button>
                            <button onClick={handleCancel} className="card-button">No</button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label htmlFor="pouchNumberSearch">Pouch Number</label>
                                <input 
                                    id="pouchNumberSearch"
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search Pouch Number"
                                />
                                <select 
                                    id="pouchNumberDropdown"
                                    value={dropdownPouchNumber}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        setDropdownPouchNumber(e.target.value);
                                    }}
                                >
                                    <option value="Search for Pouch">Select Pouch</option>
                                    {filteredPouchNumbers.map((number, index) => (
                                        <option key={index} value={number}>
                                            {number}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit" className="card-button">Pack to Carrier {user.carrier}</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );

    
};

export default NSCarrierPack;
