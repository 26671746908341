import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const CarrierPack = ({token}) => {
    const [pouchBarcode, setPouchBarcode] = useState('');
    const [pouchStatus, setPouchStatus] = useState('');
    const [confirmAction, setConfirmAction] = useState(false); // For confirmation state
    const user = useSelector((state) => state.user); // Use the stored user data
    const [pouchNumber, setPouchNumber] = useState(''); // New state for Pouch Number


    useEffect(() => {
        const fetchPouchStatus = async () => {
            try {
                const response = await axios.get(`/api/pouch_status_barcode/?pouchBarcode=${pouchBarcode}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                if (response.data[0] && response.data[0].Pouch_Status) {
                    setPouchStatus(response.data[0].Pouch_Status);
                } else {
                    setPouchStatus('Status Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch status:', error);
            }
        };
        
        const fetchPouchNumber = async () => {
            try {
                const response = await axios.get(`/api/PouchBarcode/${pouchBarcode}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                if (response.data && response.data.pouch_number) {
                    setPouchNumber(response.data.pouch_number);
                } else {
                    setPouchNumber('Not Available');
                }
            } catch (error) {
                console.error('Error fetching pouch number:', error);
            }
        };
    
        if (pouchBarcode) {
            fetchPouchStatus();
            fetchPouchNumber();
        }
    }, [pouchBarcode, token]);

    const handleProceed = () => {
        setConfirmAction(false); 
        submitCarrierHistory();  
    };

    const handleCancel = () => {
        setConfirmAction(false);  
        setPouchBarcode('');      
    };

    const submitCarrierHistory = async () => {
        const carrierHistoryData = {
            Carrier_Barcode: user.carrierBarcode,
            Pouch_Barcode: pouchBarcode,
            Station_Barcode_Start: user.station_barcode,
            datetime_start: new Date().toISOString(),
            amount: 1,
            User_Packed: user.userBarcode,  
            Carrier_Status: "Packed"
        };

        try {
            const response = await axios.post('/api/CarrierHistory/create/', carrierHistoryData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                alert('Carrier history added successfully!');
            } else {
                alert('Error adding carrier history.');
            }
        } catch (error) {
            console.error('Error adding carrier history:', error);
        }
    };

    const checkPouchExistsInCarrierHistory = async (barcode) => {
        try {
            const response = await axios.get(`/api/CarrierHistory/?pouchBarcode=${barcode}`, { // Use the barcode parameter here
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            // Check every row with the same pouch_barcode
            for (let pouchData of response.data) {
                if (pouchData.Pouch_Barcode === barcode && pouchData.Carrier_Status === 'Packed') { // Ensure you're checking the exact pouch barcode
                    // Fetch the Carrier_Number using Carrier_Barcode
                    const carrierResponse = await axios.get(`/api/Carrier/?Carrier_Barcode=${pouchData.Carrier_Barcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
    
                    const carrierData = carrierResponse.data && carrierResponse.data[0];
                    return {
                        exists: true,
                        carrierNumber: carrierData ? carrierData.carrier_number : "Unknown"
                    };
                }
            }
            return { exists: false };
    
        } catch (error) {
            console.error('Error checking pouch in CarrierHistory:', error);
            return { exists: false };
        }
    };
    


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Existing logic here
    
        // Now, check if pouch exists in CarrierHistory
        const pouchExistsInfo = await checkPouchExistsInCarrierHistory(pouchBarcode);

        if (pouchExistsInfo.exists) {
            alert(`This pouch is currently being shown as packed in the ${pouchExistsInfo.carrierNumber}`);
            setPouchBarcode('');  // Clear the pouch barcode input
            return;
        }
    
        if (pouchStatus === 'Unpacked' || pouchStatus === 'Status Not Available') {
            alert("This is showing as unpacked. Please ensure it is packed");
            setPouchBarcode('');  // Clear the pouch barcode input
            return;
        }
    
        if (pouchStatus === 'QAed') {
            submitCarrierHistory();
            return;
        }
    
        if (pouchStatus === 'Partially QAed' || pouchStatus === 'Packed') {
            setConfirmAction(true);
            return;
        }
    };


    
    return (
        <div className="packing-app">
            <div className="packing-input-container">
                <h2>Pack Carrier</h2>
                <div className="packing-main-content">
                    {confirmAction ? (
                        <div>
                            <p>This is showing as {pouchStatus} and has not been QAed. Are you sure you want to continue?</p>
                            <button onClick={handleProceed} className="card-button">Yes</button>
                            <button onClick={handleCancel} className="card-button">No</button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label>Pouch Barcode</label>
                                <input 
                                    type="text"
                                    value={pouchBarcode}
                                    onChange={(e) => setPouchBarcode(e.target.value)}
                                    placeholder="Enter Pouch Barcode"
                                />
                                <p>Pouch Number: {pouchNumber}</p> {/* Displaying the fetched Pouch Number */}
                            </div>
                            <button type="submit" className="card-button">Pack to Carrier {user.carrier}</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CarrierPack;
