// UnpackingHeader.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../static/CSS/HeaderPacking.css'; // Make sure you have this CSS file

const UnpackingHeader = ({ token, showBarcodeInput, setShowBarcodeInput }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { name, station, pouchBarcode, pouchNumber } = user;
  const [isInCarrier, setIsInCarrier] = useState(false);

  useEffect(() => {
    const checkCarrierStatus = async () => {
      try {
        const response = await axios.get(`/api/Carrier_Status_All/${pouchNumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the pouch number exists in the carrier status data
        const foundPouch = response.data.find(item =>
          item.Pouch_Number === pouchNumber && item.Carrier_Status === 'Packed'
        );

        // Set isInCarrier to true only if the pouch is found and it is 'Packed'
        if (foundPouch) {
          setIsInCarrier(true);
        } else {
          setIsInCarrier(false);
        }
      } catch (error) {
        console.error('Error fetching carrier status:', error);
        setIsInCarrier(false);  // Default to false if there's an error
      }
    };

    checkCarrierStatus();
  }, [pouchNumber, token]); // Dependencies for useEffect

  const handlePackButtonClick = () => {
    if (isInCarrier) {
      alert('The pouch is in a carrier. Please remove it before unpacking.');
      return;
    }
    if (!user || !station || !pouchBarcode) {
      let missingData = [];
      if (!user) missingData.push('Username');
      if (!pouchBarcode) missingData.push('Pouch Barcode');
      if (!station) missingData.push('Station Name');

      alert(`Please provide ${missingData.join(', ')}`);
      return;
    }

    navigate('/unpack', {
      state: {
        user: user,
        stationName: station,
        pouchBarcode: pouchBarcode,
      },
    });
  };

  const handleMedicineNotPresent = () => {
    if (isInCarrier) {
      alert('The pouch is in a carrier. Please remove it before reporting medicine as not present.');
      return;
    }

    navigate('/missingreasons');
  };

  return (
    <div className="packing-header">
      <div className="header-left">
        <h2 className='packing-title'>Unpacking</h2>
        <p className="user-greeting">Hi {name}</p>
        <button className="switch-button" onClick={() => setShowBarcodeInput(!showBarcodeInput)}>
          {showBarcodeInput ? 'Switch to Manual' : 'Switch to Barcode'}
        </button>
      </div>
      <div className="header-right">
        <div className="unpacking-details">
          <div className="unpacking-detail">
            <span className="detail-label">Pouch Number:</span>
            <span className="detail-value">{pouchNumber}</span>
          </div>
          <div className="unpacking-detail">
            <span className="detail-label">Station Name:</span>
            <span className="detail-value">{station}</span>
          </div>
          <div className="unpacking-actions">
            <button className="pack-button" onClick={handlePackButtonClick}>UnPack</button>
            <button className="missing-button" onClick={handleMedicineNotPresent}>Medicine not present</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnpackingHeader;
